import IMAGES from "./Images"

export const ServicesInfo = [
    {
        image: IMAGES.websiteServiceIcon, 
        title: "WEBSITE SERVICES",
        text: "We design and develop all types of websites for any business. From informational sites, landing pages to online courses and Ecommerces.",
        to: "/web-design-company-in-maryland"
    },
    {
        image: IMAGES.websiteServiceIcon, 
        title: "SEO",
        text: "Position your business as a top result with our SEO services, from detailed keyword analysis for efficient campaigns to content creation for organic website growth.",
        to: "/seo-local-maryland"
    },
]

export const ProjectsInfo = [
    {
        image: IMAGES.bmaMOck, 
        title: "bmalandscaping.com",
        to: "https://bmalandscaping.com"
    },
    {
        image: IMAGES.wdcttMock, 
        title: "wdctt.com",
        to: "https://wdctt.com"
    },
    {
        image: IMAGES.safeEgressMock, 
        title: "safeegresscompany.com",
        to: "https://safeegresscompany.com"
    },
    {
        image: IMAGES.ltaFlightMock, 
        title: "ltaflightmagazine.com",
        to: "https://ltaflightmagazine.com"
    },
    {
        image: IMAGES.csweldingMock, 
        title: "csweldingmd.com",
        to: "https://csweldingmd.com"
    },
    {
        image: IMAGES.safeguardMock, 
        title: "safeguardpublicadjusting.com",
        to: "https://safeguardpublicadjusting.com"
    },
    {
        image: IMAGES.acgMock, 
        title: "acgllus.com",
        to: "https://acgllus.com"
    },
    {
        image: IMAGES.tagtitleMock, 
        title: "alfonsotitle.com",
        to: "https://alfonsotitle.com"
    },
]

export const TeamMembersData = [
    {
        image: IMAGES.alfonsoQ, 
        name: "ALFONSO QUINONEZ",
        title: "CHIEF EXECUTIVE OFFICER"
    },
    {
        image: IMAGES.julianQ, 
        name: "JULIAN QUINONEZ",
        title: "CHIEF FINANCIAL OFFICER"
    },
    {
        image: IMAGES.camiloR, 
        name: "CAMILO RAMOS",
        title: "CHIEF OPERATIONAL OFFICER"
    },
    {
        image: IMAGES.sebastianA, 
        name: "SEBASTIAN ARDILA",
        title: "CHIEF SEO CREATOR"
    },
    {
        image: IMAGES.dannaM, 
        name: "DANNA MENESES",
        title: "PROJECT MANAGER"
    },
    {
        image: IMAGES.estebanA, 
        name: "ESTEBAN ATEHORTUA",
        title: "WEB DESIGNER"
    },
    {
        image: IMAGES.danielR, 
        name: "DANIEL RODRÍGUEZ",
        title: "SENIOR VIDEO EDITOR"
    },
    {
        image: IMAGES.johanO, 
        name: "JOHAN OSMAN",
        title: "SENIOR SEO DEVELOPER"
    },
    {
        image: IMAGES.marianS, 
        name: "MARIAN SALAZAR",
        title: "SEMI-SENIOR DEVELOPER"
    },
    {
        image: IMAGES.juanD, 
        name: "JUAN DIAZ",
        title: "JUNIOR DEVELOPER"
    },
    {
        image: IMAGES.jorgeI, 
        name: "JORGE ISSAC",
        title: "JUNIOR DEVELOPER"
    },
]

export const areasServe = [ "BALTIMORE, MD", "BETHESDA, MD", "FREDERICK, MD", "GREENBELT, MD", "ROCKVILLE, MD", "ELKRIDGE, MD", "ELLICOTT CITY, MD", "LANHAM, MD", "ANNAPOLIS, MD", "ODENTON, MD", "COLUMBIA, MD"]

export const WebsiteMap = [
    {
        name: "Home",
        link: "/"
    },
    {
        name: "SEO Services",
        link: "/seo-local-maryland"
    },
    {
        name: "Website Design",
        link: "/web-design-company-in-maryland"
    },
    {
        name: "Portfolio",
        link: "/portfolio"
    },
]

export const SeoIndustries = [
    {
        name: "SEO Services for Cleaning Industries",
        link: "https://qadigitalads.com/en/seo-services-for-cleaning-industries/"
    },
    {
        name: "SEO Services for Construction Industries",
        link: "https://qadigitalads.com/en/seo-services-for-construction-industries/"
    },
    {
        name: "SEO Services for Landscaping Industries",
        link: "https://qadigitalads.com/en/seo-for-landscaping-industries-with-qa-digital-advertising/"
    },
]

export const ContacUs = [
    {
        name: "240-593-6567 (Eng)",
        href: "tel:+12405936567",
        maxWidth:24,
        image: IMAGES.phoneWhite,
    },
    {
        name: "240-521-8700 (Spa)",
        href: "tel:+12405218700",
        maxWidth:24,
        image: IMAGES.phoneWhite,
    },
    {
        name: "240-708-0023 (Spa)",
        href: "tel:+12407080023",
        maxWidth:24,
        image: IMAGES.phoneWhite,
    },
    {
        name: "Hi@qadigitalads.com",
        href: "mailto:hi@qadigitalads.com",
        maxWidth:24,
        image: IMAGES.envelop
    },
]


export const ProjectsPortfolio = [
    {
        image: IMAGES.port1, 
        to: "https://ltaflightmagazine.com"
    },
    {
        image: IMAGES.port2, 
        to: "https://sofiafashionllc.com/"
    },
    {
        image: IMAGES.port3, 
        to: "https://rodentproofcrew.com/"
    },
    {
        image: IMAGES.port4, 
        to: "https://icandoitllc.com/"
    },
    {
        image: IMAGES.port5, 
        to: "https://atticrepairguys.com/"
    },
    {
        image: IMAGES.port6, 
        to: "https://kpkenterprise.com/"
    },
    {
        image: IMAGES.port7, 
        to: "https://marylandlandscapeservices.com/"
    },
    {
        image: IMAGES.port8, 
        to: "https://irmacleaningmaryland.com/"
    },
    {
        image: IMAGES.port9, 
        to: "https://jandrroofing.com/"
    },
    {
        image: IMAGES.port10, 
        to: "https://airqualitytechnologyinc.com/"
    },
    {
        image: IMAGES.port11, 
        to: "https://karinacleaningmd.com/"
    },
    {
        image: IMAGES.port12, 
        to: "https://cepheus-properties.com/"
    },
    {
        image: IMAGES.port13, 
        to: "https://tcbedc.com/"
    },
    {
        image: IMAGES.port14, 
        to: "https://galvantreeexperts.com/"
    },
    {
        image: IMAGES.port15, 
        to: "https://fdautorepairdc.com/"
    },
    {
        image: IMAGES.port16, 
        to: "https://alffchurchmd1.org/"
    },
    {
        image: IMAGES.port17, 
        to: "https://urelaxmoving.com/"
    },
    {
        image: IMAGES.port18, 
        to: "https://poolprosllc.net/"
    },
    {
        image: IMAGES.port19, 
        to: "https://invisualsign.com/"
    },
    {
        image: IMAGES.port20, 
        to: "https://eaglenationalmovers.com/"
    },
    {
        image: IMAGES.port21, 
        to: "https://bmexpressny.com/"
    },
    {
        image: IMAGES.port22, 
        to: "https://eastpointllcdmv.com/"
    },
    {
        image: IMAGES.port23, 
        to: "https://adarcourtreporting.com/"
    },
    {
        image: IMAGES.port24, 
        to: "https://withinhypnosis.com/"
    },
]

export const SeoServices = [
    {
        title: 'Website SEO optimization & Redesign',
        description: 'The “Website SEO Optimization & Redesign” plan is our most complete service when it comes to SEO. Our SEO team analyzes your website first to create a positioning plan. This plan aims to create a synergy between the design and content, increasing reading time for users because the content is not only appealing, but insightful-Google loves relevant content! Lastly, this plan includes auditing the backend of your website to make sure everything is optimized for positioning–elements like meta-tags, H1, snippet titles, and much more.'
    },
    {
        title: 'Single-page SEO optimization',
        description: 'As demonstrated by its name, this service is focused on optimizing a single internal page of your website using the best SEO strategies. Therefore, this plan may be an excellent match for your company because it allows you to grow at your pace—moreover, this plan is more cost-effective than paid Google Ads or Facebook Ads in the long run. Once you stop paying for Ads, you stop being the first result; with SEO content, it lives online forever as long as your domain is up.'
    },
    {
        title: 'Website SEO optimization',
        description: 'The SEO Optimization plan is focused on working with your current structural design. First, we review the content and programming to understand if the most effective strategies have been employed. Once we finish our analysis, our content creation and SEO experts incorporate or restructure the website content to improve the website’s SEO.'
    },
    {
        title: 'Google My Business optimization',
        description: 'Congratulations! If you’re interested in this service, it’s because you know that appearing on the first page of results isn’t enough. You understand the importance of offering an excellent impression on your Google My Business since your listing is essentially your first impression. Conversely, if your listing isn’t optimized and gives a poor image, your clients will probably prefer doing business with your competition. Therefore it’s crucial to strengthen and constantly maintain these strategies up to date.'
    },
    {
        title: 'Landing page SEO strategy',
        description: 'An SEO Landing Page is an excellent way to quickly promote a service or product. Remember that landing pages are more efficient when combined with a Google Ads campaign. Any SEO plan by itself needs at least three months to produce results, especially if the website doesn’t have a strong following. One advantage of having a landing page with an excellent SEO strategy is that it allows you to run ads with Google at a cost-effective price and obtain better results per day than a landing page that isn’t optimized.'
    },
    {
        title: 'Local SEO',
        description: 'The local SEO service is fundamental for any business that wants to boost its products and services in a specific area. More than ever before, most people conduct a preliminary internet search before going to a store or business. However, if your business is located in an excellent area and doesn’t appear locally in the results–your clients will prefer going directly to a company with a local listing and more positive reviews.'
    }
]

export const HowWeHelp = [
    {
        linetitle1: 'Why Local SEO',
        linetitle2: 'Matters:',
        description: "Local SEO is more than just a buzzword; it's a strategy tailored for businesses aiming to dominate their local market. When potential customers in your vicinity search for services you offer, we ensure you're their first choice. Whether it's optimizing your Google My Business page, crafting engaging content, or ensuring you rank high on Google Maps, our strategies are designed to connect you with customers precisely when they're looking for you."
    },
    {
        linetitle1: 'Mobile-First',
        linetitle2: 'Approach:',
        description: "With the surge in mobile searches, we prioritize optimizing for mobile devices. Our experience underscores the significance of local search optimization, especially for businesses catering to specific geographical areas. When someone in your locale searches for a service you offer, we ensure you're their top pick."
    },
    {
        linetitle1: 'Quality Over',
        linetitle2: 'Quantity:',
        description: "It's not just about driving traffic; it's about attracting the right audience. For instance, if you run a bed and breakfast in Bethesda, Maryland, our strategies will target individuals specifically searching for such accommodations in Bethesda, ensuring higher conversion rates."
    },
    {
        linetitle1: 'Join the Ranks of Our',
        linetitle2: 'Success Stories:',
        description: "Since 2006, we've optimized countless businesses, helping them rank on the first page of Google for relevant keywords. Our affordable local SEO services are tailored to grow your online presence and business. Let's embark on this journey together and make your business the local favorite."
    },
    {
        linetitle1: 'Ready to Dominate Your',
        linetitle2: 'Local Market?',
        description: "Let's set up a meeting at our Silver Spring location. With QA Digital Advertising, you're not just another client; you're a partner. Together, we'll craft a strategy that puts you ahead of the competition."
    }
]

export const SeoResults = [
    
    {
        image: IMAGES.towerPavigMock, 
        title: "towerpavingcompany.com",
        to: "https://towerpavingcompany.com",
        description: 'Tower Paving Company (Millersville, Maryland)'
    },
    {
        image: IMAGES.acgllcMock1, 
        title: "acgllus.com",
        to: "https://acgllus.com",
        description: 'ACG - IFTA and irp Silver Spring (Silver Spring, MD)'
    },
    {
        image: IMAGES.tagtitleMock, 
        title: "alfonsotitle.com",
        to: "https://alfonsotitle.com",
        description: 'Alfonso Title - Tag and title office in Silver Spring'
    },
]

export const DesignServicesInfo = [
    {
        image: IMAGES.competitiveIcon, 
        title: "Competitive Analysis",
        text: "Based on your goals, we will run a full analysis of your online competitors and implement a strategy that makes you stand out from the rest!",
        to: ""
    },
    {
        image: IMAGES.profesionalIcon, 
        title: "Professional Web Design",
        text: "Our designers will transform your ideas and vision into a design that illustrates what you want to convey. Our visual strategy will lead your visitors to take action, helping you achieve your goals!",
        to: ""
    },
    {
        image: IMAGES.contentIcon, 
        title: "Content Creation",
        text: "Our professional copywriters will take care of your website’s content. The content will be written in a way that is efficient and engaging for your online visitors, as well as S.E.O driven.",
        to: ""
    },
    {
        image: IMAGES.codingIcon, 
        title: "Clean Coding",
        text: "Our programmers use the best practices for excellent performance and security. Our areas of expertise include business websites, e-commerces, educational websites, nonprofits websites, entrepreneurial websites, and many more!",
        to: ""
    },    
    {
        image: IMAGES.responsiveIcon, 
        title: "Responsive Web Design",
        text: "All our websites include a full responsive integration for any device that can surf the internet. The website’s responsive-ness is something we consider from the moment we start creating your design.",
        to: ""
    },
    {
        image: IMAGES.builSeoIcon, 
        title: "Built for SEO",
        text: "Out of millions of websites, how does Google know what to show on the first page? The answer is SEO: Search Engine Optimization. With us, you can rest assured your website will be structured for SEO.",
        to: ""
    },
]

export const MeasureDifference = [
    {
        titleLine1: 'Exceeding',
        titleLine2: 'Expectations',
        description: 'We believe every client deserves exceptional service, and we deliver by going above and beyond. From the initial consultation to the final analytics report, our team ensures a seamless, satisfying experience that meets and exceeds your highest expectations.'
    },
    {
        titleLine1: 'Customized',
        titleLine2: 'Strategies',
        description: "Your business is unique; a one-size-fits-all approach won't cut it. We delve deep into industry research, draw on the latest digital trends, and adapt to SEO evolutions to tailor strategies that fit your needs. This bespoke approach ensures your marketing efforts are as effective and efficient as possible."
    },
    {
        titleLine1: 'Transparent',
        titleLine2: 'Communication',
        description: "We prioritize clear and consistent communication, keeping you in the loop every step of the way. With regular updates and accessible reports, you'll see the tangible results of our efforts each month, allowing you to measure progress and ROI with clarity and confidence."
    },
    {
        titleLine1: 'Quality Traffic',
        titleLine2: 'and ROI',
        description: 'Our high standards in digital marketing are designed to attract not just any traffic but the right traffic. We target the most likely convert audience, bringing valuable leads to your site. By focusing on quality over quantity, we ensure that your investment translates into meaningful growth for your business.'
    },
]
