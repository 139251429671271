import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ServicesCard = ({image, title, text, to, marg, imgWidth, imgHeight}) => {
    return (
        <>
            <Col xl={6} xs={12} className={`home-service p-4 ${marg}`}>
                <img src={image} width={imgWidth} height={imgHeight}></img>
                <h3 className="kumbh py-xl-2 pt-3 pb-0 se-title bold h-sm-20">{title}</h3>
                <p className="py-xl-3 py-2 montserrat t-sm-14">{text}</p>
                {to == '' ? '' 
                : (<Link to={to} className="se-button text-white montserrat" >See More</Link>)}
            </Col>
        </>
    )
}

export default ServicesCard;