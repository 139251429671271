import React from "react";
import IMAGES from "../Images";

const ReviewIndividual = ({bgCicle, rvIn, initial, name, review}) => {
    return (
        <>
        	<div className={"bg-white row review-cont fit-content pt-3 px-2 pb-2 "+rvIn}>
				<div className="col-4 p-0">
					<span className={"initial-review h-45 h-sm-25 text-white lato "+bgCicle}>{initial}</span>
				</div>
				<div className="col-8 p-0 text-start">
					<h4 className="kumbh m-0 t-18 bold color-22 t-sm-12">{name}</h4>
					<img src={IMAGES.starReview} className="star-review"/>
				</div>
				<div className="col-12 text-start montserrat px-0 px-xl-3">
					<p className="m-0 t-sm-9 t-11">{review}</p>
				</div>
			</div>
        </>
    )
}

export default ReviewIndividual;