import React, { useState } from "react";
import PropTypes from "prop-types";

const YoutubeEmbedLazy = ({ embedId}) => {

    const [showVideo, setShowVideo] = useState(false)
    return (
        <>
        {showVideo ? 
        (<div className="video-responsive">
            <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}?&autoplay=true `}
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            loading="lazy"
            />
        </div>
        ) : ( 
        <button type="button" onClick={()=>{setShowVideo(true)}} className="border-0 p-0 bg-transparent thumb-button" 
        style={{
            backgroundImage: `url(https://img.youtube.com/vi/${embedId}/maxresdefault.jpg)`}}> 
                <i className="bi bi-youtube color-c4 t-80"></i>
            </button>)} 
        </>
    )
};

YoutubeEmbedLazy.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbedLazy;