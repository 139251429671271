
import React, { useState } from "react";
import "../App.css";

import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import IMAGES from "../Images";
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {


  return (
  <>

<Navbar collapseOnSelect expand="lg" sticky="top" fixed="top" className="bg-grey py-0">
    <Container>
      <Navbar.Brand as={Link} to={"/"} className="py-3 px-3 mx-0">
        <img src={IMAGES.logoqa} width={60}></img>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" className="text-white-icon"/>
      <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto my-2 my-lg-0">
            <Nav.Link href="/" className="text-white" >Home</Nav.Link>
            <NavDropdown title={<span className="text-white my-auto">Services</span>} id="collapsible-nav-dropdown" className="text-white">
              <NavDropdown.Item href="seo-local-maryland" >SEO Services</NavDropdown.Item>
              <NavDropdown.Item href="web-design-company-in-maryland" >Website Design</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="portfolio" className="text-white">Portfolio</Nav.Link>
          </Nav>
          <Nav>
          <Nav.Link href="tel:+12405936567" className="text-white"><button className="h-button mb-3 mb-xl-0">240-593-6567</button></Nav.Link>
          </Nav>
        </Navbar.Collapse>
    </Container>
  </Navbar>
  </>
  );
}

export default Header;

