import './App.css';
import React from "react";
import routes from './Routes';
import {
	Routes,
	Route,
} from "react-router-dom";
import Header from './Components/Header';
import Footer from './Components/Footer';

function App() {
  return (
    <>
    <Header />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.component} />
        ))}
      </Routes>
    <Footer />
    </>
  );
}

export default App;
