import { Col } from "react-bootstrap"
import Divider from "./Divider"

const SEOService = ({title, description}) => {
    return(
        <>
        <Col xs={12} xl={4} className="px-xl-5 py-4 seo-service mx-xl-2 my-xl-4 mx-3 my-3 px-3">
            <h3 className="h-20 montserrat color-62 text-center w-800 mx-4">{title}</h3>
            <Divider width="30%" color='#20D9E4' height="7px" clas="mx-xs-auto mx-auto my-4" />
            <p className="montserrat color-36 text-center t-14">{description}</p>
        </Col>
        </>
    )
}

export default SEOService;