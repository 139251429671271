import { Col } from "react-bootstrap";

const TeamMember = ({image, name, title}) => {
    return (
            <Col xl={3} xs={6} className="align-self-center text-center">
                <figure className="blurback">
                    <img src={image}  alt="" width={"100%"} />
                </figure>
                <p className="montserrat team-name text-white pt-3 pb-0 m-0 t-sm-11">{name}</p>
                <p className="montserrat team-title t-sm-9">{title}</p>
            </Col>
    )
}

export default TeamMember;