import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Divider from "../Components/Divider";
import AddressPhone from "../Components/AddressPhone";
import IMAGES from "../Images";
import FormBlack from "../Components/FormBlack";
import YoutubeEmbed from "../Components/YoutubeEmbed";
import TeamSection from "../Components/TeamSection";
import ReviewSection from "../Components/ReviewsSection";
import { SeoServices, HowWeHelp, SeoResults } from "../data";
import SEOService from "../Components/SEOService";
import HowWeHelpCard from "../Components/HowWeHelp";
import SeoResultsCard from "../Components/SeoResults";
import { Link } from "react-router-dom";
import Formfooter from "../Components/FormFooter";

const SEO = () => {
	return (
		<>
			<div className="seo-bg-banner">
				<Container>
					<Row className="py-5">
						<Col xl={8} className="ps-xl-5 px-4 pt-2 align-self-bottom">
							<h1 className="text-white montserrat h-20 t-sm-14 w-800 m-0 pt-1 text-center text-xl-start">LOCAL SEO COMPANY IN MARYLAND</h1>
							<h2 className="lato w-900 h-60 seo-banner-title h-sm-30 text-center text-xl-start"><span className="h-40 h-sm-20" style={{lineHeight:"1em"}}>GROW YOUR BUSINESS THROUGH</span><br/> DIGITAL<br/> MARKETING</h2>
							<Row>
								<Col xl={12}>
									<h3 className="text-white kumbh-sans th-25 text-center text-xl-start" style={{ paddingTop: 20 }}>Visit Us</h3>
									<Divider width="50%" color='#09F2F2' height="3px" clas="mx-xs-auto"/>
								</Col>
								<Col xl={5} className="pt-4">
									<AddressPhone image={IMAGES.mapAlt} href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x89b7ddca8bcfe903:0x5982abeea75887d5?entry=s&sa=X&hl=en-us&ved=2ahUKEwjM9q25yOX5AhVDFlkFHSwEDFoQ4kB6BAgVEAI" classes="a-address-tel" whereTo="_blank">706 Crain Highway North, Suite A Glen Burnie, MD 21061</AddressPhone>
									<AddressPhone image={IMAGES.mapAlt} href="https://goo.gl/maps/ojnpLdxN4A3UDm1v7" classes="a-address-tel" whereTo="_blank">817 Silver Spring Ave. Silver Spring, MD 20910</AddressPhone>
								</Col>
								<Col xl={5} className="pt-2 pt-xl-4">
									<AddressPhone image={IMAGES.phoneAlt} href='tel:+1240-593-6567' classes={'a-address-tel'} whereTo="_self">240-593-6567 (Eng.)</AddressPhone>
									<AddressPhone image={IMAGES.phoneAlt} href='tel:+1240-521-8700' classes={"a-address-tel"} whereTo="_self">240-521-8700 (Spa.)</AddressPhone>
								</Col>
							</Row>
						</Col>
						<Col xl={12} className="py-xl-4 pt-4 pb-5 ps-xl-5 text-center text-xl-start">
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2">240-593-6567</a>
						</Col>
					</Row>
				</Container>
			</div>

			<FormBlack textTitle={'Are you looking for an SEO Company in Maryland? Contact Us!'}/>

			<div className="dont-pay-ads py-2 py-xl-5">	
				<Container className="pt-xl-5 pt-4 px-4 px-xl-0">
					<Row>
						<Col xl={5}>
							<img src={IMAGES.redirectToSEO} width={"100%"} className="py-xl-4 pt-4 pb-5" ></img>
						</Col>
						<Col xl={7} className="align-self-center">
							<p className="lato h-20 color-53 bold">STOP PAYING FOR ADS!</p>
							<h2 className="montserrat bold h-35 h-sm-25">Redirect your budget from paid ADS to Search Engine Optimization and see your business grow.</h2>
							<p className="py-3 montserrat t-14 mb-0 mb-xl-3">
							In today’s digital landscape, the importance of digital marketing, including SEO, cannot be overstated. While platforms like Facebook, 
							Instagram, and Google Ads offer valuable promotional opportunities, their ongoing costs can add up over time, especially if the desired 
							results are not achieved.<br /><br />
							That’s where SEO comes in. As a leading Maryland SEO firm, QA Digital Advertising offers an alternative to continuous ad spending. By 
							optimizing your website for search engines, we help amplify your online presence and drive organic traffic without recurring ad expenses.<br /><br />
							Make the shift from costly ads to a strategic investment in SEO. With QA Digital Advertising, you can expect increased traffic, enhanced 
							leads, and improved engagement—all at a more economical cost per click through organic SEO. So, when you’re searching for an SEO company 
							near you, look no further than QA Digital Advertising to elevate your online presence.
							</p>
						</Col>
					</Row>
					<Row className="pt-xl-5 pt-3">
						<Col xl={8} className="pe-xl-5 pe-2">
						<h2 className="lato h-35 bg-text-SEO w-600 pe-xl-3 h-sm-25">Find Yourself Searching "SEO Company Near Me"? You've Come to the Right Place. QA Digital is the Top SEO Company in Maryland!</h2>
						</Col>
						<Col xl={12} className="pe-3">
						<p className="py-3 montserrat t-14 mb-0">
						When it comes to fulfilling your SEO needs, partnering with QA Digital Advertising is the right choice. As your trusted SEO company in
						 Maryland, we bring years of expertise and a proven track record of success optimizing online business visibility. Our strategies are designed
						to enhance your website’s ranking on search engines, driving organic traffic and delivering tangible results. With QA Digital Advertising, you can rest assured knowing that your SEO efforts are in the hands of dedicated professionals committed to helping your business thrive in today’s competitive digital landscape.
							</p>
						</Col>
					</Row>
				</Container>
			</div>

			<div>
				<Container className="p-3">
					<Row className="pb-xl-4 pb-3 px-3 px-xl-0">
						<Col xl={5} className="px-0">
							<h3 className="lato h-35 h-tittle h-sm-25">Discover SEO with us!
							</h3>
							<Divider width="70%" color='#20D9E4' height="6px" />
						</Col>
					</Row>
					<Row className="p-xl-5 p-3">
						<YoutubeEmbed embedId="-yByjM4Obw4?si=PzW3_-IMPy2atJMb"/>
					</Row>
				</Container>
			</div>

			<div className="py-5">
				<Container className="top-maryland-seo pt-5 pb-5 pb-xl-5">
					<Row className="justify-content-center pb-xl-5">
						<Col xl={7} className="px-4 px-xl-3 pb-5">
							<h2 className="lato h-45 text-white text-center h-sm-35">We are the <span className="w-600">top Maryland SEO company</span> to boost your revenue and reach more organic growth.</h2>
							<p className="color-36 text-center py-4 py-xl-5 mb-5"><b>Don’t Believe Us? Try Us!</b><br/>
							Search on Google for the following keywords and see for yourself how good we are and how much money we have helped our clients save and earn through organic search results.</p>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="success-sec">
				<Container>
				<Row className="justify-content-around px-4 px-xl-5">
						<Col xl={3} className="ps-4 pe-2 py-xl-5 py-3 mb-4 mb-xl-0 sucsses-example">
							<Row className="justify-content-center ps-2 pe-4 pb-4">
								<Col xl={8}>
									<img src={IMAGES.mistyLogoColor} width={'144px'} />
								</Col>
							</Row>
							<p className="t-18 color-56 montserrat bold mb-2">Top 1</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Keyword: </b>
								<a href="https://www.google.com/search?q=Cleaning+Services+In+Pasadena%2C+MD&rlz=1C1VDKB_esCO1047CO1047&oq=Cleaning+Services+In+Pasadena%2C+MD&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIICAEQABgWGB4yCAgCEAAYFhge0gEHMTU5ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8" target="_blank" className="color-36">Cleaning Services In Pasadena, MD</a>
							</p>
							<p className="color-36 t-14 montserrat">We help them save over $1,000 per month (Estimated cost if they were to invest on Google Ads for the same keyword)</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Competitors: </b>Private</p>
						</Col>
						<Col xl={3} className="ps-4 pe-2 py-xl-5 py-3 mb-4 mb-xl-0 sucsses-example">
							<Row className="justify-content-center ps-2 pe-4 pb-4">
								<Col xl={12}>
									<img src={IMAGES.bmaLogoColor} width={'249px'} />
								</Col>
							</Row>
							<p className="t-18 color-56 montserrat bold mb-2">Top 1</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Keyword: </b>
								<a href="https://www.google.com/search?q=Inground+liner+contractor+in+Pasadena%2C+Maryland&rlz=1C1VDKB_esCO1047CO1047&oq=Inground+liner+contractor+in+Pasadena%2C+Maryland&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBBzE1MWowajSoAgCwAgA&sourceid=chrome&ie=UTF-8" target="_blank" className="color-36">Inground liner contractor in Pasadena, Maryland</a>
							</p>
							<p className="color-36 t-14 montserrat">We help them save over $1,100 per month (Estimated cost if they were to invest in Google Ads for the same keyword)</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Competitors: </b>Private</p>
						</Col>
						<Col xl={3} className="ps-4 pe-2 py-xl-5 py-3 mb-4 mb-xl-0 sucsses-example">
							<Row className="justify-content-center ps-2 pe-4 pb-4">
								<Col xl={12}>
									<img src={IMAGES.habibLogo} width={'249px'} />
								</Col>
							</Row>
							<p className="t-18 color-56 montserrat bold mb-2">Top 3 & going up</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Keyword: </b>
								<a href="https://www.google.com/search?q=Personal+Trainer+in+Glen+Burnie%2C+MD&oq=Personal+Trainer+in+Glen+Burnie%2C+MD&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBBzQyNmowajeoAgCwAgA&sourceid=chrome&ie=UTF-8" target="_blank" className="color-36">Personal Trainer in Glen Burnie, MD</a>
							</p>
							<p className="color-36 t-14 montserrat">We help them save over $17,000 per month (Estimated cost if they were to invest in Google Ads for the same keyword)</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Competitors: </b>Private</p>
						</Col>
					</Row>

				</Container>
			</div>

			<div className="py-2 py-xl-5">
				<Container className="pt-xl-5 mt-4">
					<Row className="pt-xl-5 mt-3 pt-2">
						<Col xl={6} className="px-4">
							<p className="lato h-35 bg-text-SEO mb-5 h-sm-25 lh-37 lh-sm-27"><b className="block bold h-45 h-sm-30">Take a look at our portfolio </b>
							and see for yourself why we are also the best web design company in Maryland:</p>
							<a href="/portfolio" className="my-5 t-14 color-62 montserrat" style={{textDecoration:'none'}}>https://qadigitalads.com/en/portfolio/</a>
							<p className="montserrat color-36 t-14 mt-5 pe-xl-4"><span className="t-18 bold block">REAL CLIENTS, REAL RESULTS: </span>The examples showcased are just three of the countless clients we’ve successfully assisted in Maryland, among many other states. At QA Digital Advertising, our team is 100% passionate and dedicated to propelling our clients to the top spots on search engines. Every success story we share reflects our commitment and our clients’ trust. Our mission goes beyond numbers; it’s about crafting enduring success stories.</p>
							<p className="py-4 montserrat color-36 t-14 w-500 mb-0">Your brand could be next on our list of triumphs!</p>
							<Divider width="80%" color='#20D9E4' height="7px" clas="my-xl-5 my-2"/>

							<p className="lato h-45 bg-text-SEO w-800 pe-3 h-sm-30 pt-3 pt-xl-0 lh-12">We are QA Digital Advertising</p>
							<p className="montserrat color-36 t-14">A marketing company based in Maryland. We specialize in helping businesses of all sizes increase their brand exposure through our web positioning services. With us, you can find the perfect marketing ally that will help you improve your sales and dominate the market.</p>
							<p className="py-4 montserrat color-36 h-20 bold">Call us today for a free consultation!</p>
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2">240-593-6567</a>
						</Col>
						<Col xl={6} className="px-5 pt-5 pb-4 text-center"><img src={IMAGES.realClientsSEO1} width={'90%'}></img></Col>
					</Row>
				</Container>
			</div>

			<div className="pt-100 whidthus mt-4 mt-xl-0">
				<Container>
					<Row className="pe-xl-5 px-4 ps-xl-2">
						<Col xl={6} className="nm-top-100 nm-top-sm-60 pb-4 pb-xl-0"><img src={IMAGES.findWithUs} width={'90%'} className="fw-sm" /></Col>
						<Col xl={5} className="pe-xl-5 pe-0 ps-0 pb-5 pb-xl-2 align-self-center">
							<p className="color-56 montserrat th-25 bold pe-3" style={{lineHeight:'1.7rem', letterSpacing:"0.2"}}>With us,<br />
							you can find the perfect marketing ally that will help you improve your sales and dominate the market.</p>
							<p className="montserrat color-36 t-14 w-500 mb-5 mb-xl-2">We Help You Drive Quality Traffic to Your Website and Convert Visitors into Loyal Customers. Since 2006, QA Digital Advertising has empowered numerous businesses to boost their sales by enhancing their search visibility.</p>
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2 w-600">Call us today for a free consultation!</a>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="py-5 bg-seo-importance">
				<Container className="py-5">
					<Row>
						<Col xl={6} className="px-4 px-xl-2">
						<h2 className="color-20 lato h-50 h-sm-35 bold SEO-importance-why">WHY <span className="block SEO-importance w-800 h-sm-100 lh-12">SEO</span> IS SO IMPORTANT:</h2>
						</Col>
						<Col xl={4} className="pe-xl-5 pe-4 ps-4 pt-4 pt-xl-0">
						<p className="t-14 montserrat color-36 text-justify">More than 90% of all buying decisions start with an online search.</p>
						<Divider width="15%" color='#20D9E4' height="6px" clas="my-3"/>
						<p className="t-14 montserrat color-36 text-justify">Less than 20% of users go to the second page of search results. If your website is not on the first page of search results, you are missing out on many potential customers.</p>
						<Divider width="15%" color='#20D9E4' height="6px" clas="my-3"/>
						<p className="t-14 montserrat color-36 text-justify">SEO is the process of optimizing your website so that it ranks higher in search engine results pages (SERPs).</p>
						<Divider width="15%" color='#20D9E4' height="6px" clas="my-3"/>
						<p className="t-14 montserrat color-36 text-justify">Many factors affect your website's ranking, including the quality of your content, the number of backlinks to your website, and the keywords you use.</p>
						<Divider width="15%" color='#20D9E4' height="6px" clas="my-3"/>
						<p className="t-14 montserrat color-36 text-justify">SEO can be complex and time-consuming, but increasing your website's traffic and sales is worth it.</p>
						<Divider width="15%" color='#20D9E4' height="6px" clas="my-3"/>
						</Col>
						<Col xl={12} className="text-center pt-5 px-4 px-xl-2">
						<p className="t-14 montserrat color-36 bold">At QA Digital Advertising can help you with your SEO needs.</p>
						<p className="t-14 montserrat color-36">We offer various services, including keyword research, on-page optimization, monthly SEO plans, and more.</p>
						<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2 w-600">Call us today for a free consultation!</a>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="py-5">
				<Container className="seo-and-keywords px-4 ps-xl-5 pb-5 pt-xl-3 pt-0">
					<Row className="ps-xl-3">
						<Col xl={6} className="px-xl-5 align-self-center order-1 order-xl-0">
						<h2 className="h-35 text-white montserrat">SEO AND KEYWORDS
						<span className="h-45 text-white block"> MATTER!</span></h2>
						<p className="text-white text-justify t-14 montserrat w-500">Many businesses overlook the importance of understanding how potential clients search for them online and the keywords they use. However, businesses that consistently appear on the first page of search results are those that prioritize SEO and keywords in their marketing strategy. By partnering with an experienced Local SEO company, businesses can ensure they’re visible to potential customers right when they’re searching, maximizing their online presence and opportunities for engagement.</p>
						</Col>
						<Col xl={6} className="nm-top-100 text-center order-0 order-xl-1 pb-5 pb-xl-0">
						<img src={IMAGES.seoKeywords} width={'70%'} className="seo-keywords-img fw-sm nm-top-sm-60"/>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="business-improve pt-5 pb-3">
				<Container className="pt-4">
					<Row>
						<Col xl={5}><img src={IMAGES.websiteSeo2} width={'100%'}/></Col>
						<Col xl={6} className="ps-4">
							<h2 className="lato h-35 h-tittle pe-5">How do we help your business improve?</h2>
							<Divider width="60%" color='#20D9E4' height="7px" clas="my-3"/>
							<p className="h-20 montserrat color-36 w-600 pe-5">List of steps to improve the SEO of your business in Maryland:</p>
							<Row className="mb-3">
								<Col xl={2}>
								<div style={{textAlign: "-webkit-center"}}>
									<div className="circle-shadow">
										<p className="h-45 w-800 lato bg-text-SEO-75 text-center">1</p>
									</div>
								</div>
								</Col>
								<Col xl={10}>
								<p className="t-14 montserrat color-36">The first step to improving your business SEO is understanding which of your services is most sought after and knowing how your clients search for these services.
									</p></Col>
							</Row>
							<Row className="mb-2">
								<Col xl={2} className="align-self-center">
								<div style={{textAlign: "-webkit-center"}}>
									<div className="circle-shadow">
										<p className="h-45 w-800 lato bg-text-SEO-75 text-center">2</p>
									</div>
								</div>
								</Col>
								<Col xl={10}>
								<p className="t-14 montserrat color-36">
								Analyze which city you want to target with SEO and create content around it. In our case,
								 we know that most clients search for us as: “Local Maryland SEO company", "SEO company near me”, 
								 etc. You can be sure each industry has a specific way of being searched, such as: “Remodeling company in Bethesda near me,”
								  “Cleaning company in Silver Spring near me,” etc.
									</p></Col>
							</Row>
							<Row className="mb-2">
								<Col xl={2} className="align-self-center">
								<div style={{textAlign: "-webkit-center"}}>
									<div className="circle-shadow">
										<p className="h-45 w-800 lato bg-text-SEO-75 text-center">3</p>
									</div>
								</div>
								</Col>
								<Col xl={10}>
								<p className="t-14 montserrat color-36">
								Structure or restructure the architecture of your website’s content. It’s essential to understand the keywords potential
								 clients use and learn to incorporate them effectively. On top of that, you need to bring it all together with a great visual desig
									</p></Col>
							</Row>
							<Row className="mb-2">
								<Col xl={2} className="align-self-center">
								<div style={{textAlign: "-webkit-center"}}>
									<div className="circle-shadow">
										<p className="h-45 w-800 lato bg-text-SEO-75 text-center">4</p>
									</div>
								</div>
								</Col>
								<Col xl={10}>
								<p className="t-14 montserrat color-36">
								Program and incorporate the meta descriptions and tags on the website’s backend. Remember that for a page to appear as a top
								 search result, you also have to make it easy for the search engine’s AI robots to audit. In other words, you need to have excellent
								  content directed towards humans and apply SEO strategies for the robots that look at your website.
									</p></Col>
							</Row>
							<p className="h-20 montserrat color-36 bold pe-5">Call us today for a free consultation!</p>
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2 w-600">240-593-6567</a>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="pt-5 pb-5">
				<Container className="pt-5">
					<Row>
						<Col xl={9}>
						<h2 className="lato h-45 h-tittle pe-5">
						Searching for an SEO Company in Maryland? Learn How QA Digital Advertising
						can help you attract more potential customers during their buying cycle:
						</h2>
						</Col>
					</Row>
					<Row className="pt-4">
						<Col xl={7}>
						<Row className="mb-3">
								<Col xl={2} className="align-self-center">
								<div style={{textAlign: "-webkit-center"}}>
									<div className="circle-colored">
										<p className="h-45 w-800 lato text-white text-center">1</p>
									</div>
								</div>
								</Col>
								<Col xl={10}>
								<p className="t-14 montserrat color-36">
									<span className="block t-18 color-62 monserrat bold">We understand the Buyer's Journey:</span>
									<strong>Awareness Stage:</strong> Potential customers realize they have a problem or need. At this stage, QA Digital Advertising 
									can produce educational content, such as blog posts, landing pages, or infographics, that addresses common problems 
									or needs in your industry, ensuring potential customers find you during their initial research.<br/>
									<strong>Consideration Stage:</strong> Our team will produce comparison guides, optimized internal pages, or case studies
									 that showcase how your products or services can address specific needs, guiding prospects closer to a purchase decision.<br/>
									<strong>Decision Stage:</strong> We'll highlight your brand's strengths through testimonials, detailed product or service descriptions, and free consultations to convince prospects to choose you.
								</p></Col>
						</Row>
						<Row className="mb-3">
								<Col xl={2} className="align-self-center">
								<div style={{textAlign: "-webkit-center"}}>
									<div className="circle-colored">
										<p className="h-45 w-800 lato text-white text-center">2</p>
									</div>
								</div>
								</Col>
								<Col xl={10}>
								<p className="t-14 montserrat color-36">
									<span className="block t-18 color-62 monserrat bold">Personalized Marketing:</span>
									We use data analytics to understand your audience's preferences and behaviors. Then we tailor your marketing messages and offers to resonate with individual customer segments.								</p></Col>
						</Row>
						<Row className="mb-3">
								<Col xl={2} className="align-self-center">
								<div style={{textAlign: "-webkit-center"}}>
									<div className="circle-colored">
										<p className="h-45 w-800 lato text-white text-center">3</p>
									</div>
								</div>
								</Col>
								<Col xl={10}>
								<p className="t-14 montserrat color-36">
									<span className="block t-18 color-62 monserrat bold">Leverage User-Generated Content (UGC):</span>
									We encourage satisfied clients to leave reviews, testimonials or share their success stories on your website and social media platforms. As we've seen, UGC can significantly influence buying decisions.
								</p></Col>
						</Row>
						<Row className="mb-3">
								<Col xl={2} className="align-self-center">
								<div style={{textAlign: "-webkit-center"}}>
									<div className="circle-colored">
										<p className="h-45 w-800 lato text-white text-center">4</p>
									</div>
								</div>
								</Col>
								<Col xl={10}>
								<p className="t-14 montserrat color-36">
									<span className="block t-18 color-62 monserrat bold">Local SEO Optimization:</span>
									Our SEO strategies will ensure your business stands out in local searches, capturing the attention of potential customers in your vicinity.
								</p></Col>
						</Row>
						<Row className="mb-3">
								<Col xl={2} className="align-self-center">
								<div style={{textAlign: "-webkit-center"}}>
									<div className="circle-colored">
										<p className="h-45 w-800 lato text-white text-center">5</p>
									</div>
								</div>
								</Col>
								<Col xl={10}>
								<p className="t-14 montserrat color-36">
									<span className="block t-18 color-62 monserrat bold">We Help You Foster An Online Community:</span>
									We can help you create online forums on your site where your customers can discuss various experiences related to your services. This helps your site build trust and positions you as a leader in the industry.
								</p></Col>
						</Row>
						<Row className="mb-3">
								<Col xl={2} className="align-self-center">
								<div style={{textAlign: "-webkit-center"}}>
									<div className="circle-colored">
										<p className="h-45 w-800 lato text-white text-center">6</p>
									</div>
								</div>
								</Col>
								<Col xl={10}>
								<p className="t-14 montserrat color-36">
									<span className="block t-18 color-62 monserrat bold">Continuous Engagement Tactics:</span>
									Our strategies ensure you're always in touch with potential customers, from newsletters to retargeting ads, keeping your brand 
									top-of-mind. <br />With QA Digital Advertising by your side, you'll have a tailored strategy that effectively attracts and engages 
									potential customers at every stage of their buying cycle. <br />Follow us on Instagram: <a href='https://www.instagram.com/qadigitaladvertising/' target="_blank">@qadigitaladvertising</a><br />
									#Maryland Web Design #SEO Company #SEO Marketing Near Me #Local SEO Maryland #Maryland SEO Services.
								</p></Col>
						</Row>
						</Col>
						<Col xl={5}>
						<Row>
							<Col xl={12} className="text-start"><img src={IMAGES.rectangule443} width={'80%'}/></Col>
							<Col xl={12} className="text-end howQA-image"><img src={IMAGES.howQAHelp} width={'80%'}/></Col>
						</Row>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="cta-seo-2">
				<Container>
					<Row>
						<Col xl={6} className="text-center px-5 align-self-center bg-circle-cta-seo-2 py-5">
							<h3 className="color-22 h-35 montserrat w-600 text-center pt-4">DO YOU WANT TO
							<span className="h-40 lato color-6f w-800 block"> INCREASE YOUR SALES?</span></h3>
							<h2 className="th-25 lato color-6f text-center w-800">SEARCH FOR AN "SEO COMPANY NEAR ME", AND YOU WILL FIND US!</h2>
							<p className="color-36 montserrat t-18 text-center px-5">The quicker you contact us, the faster we can help you appear as a top <strong>search result on Google.</strong></p>
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2 w-600 mb-4">Schedule an in-person or virtual meeting for free!</a>
						</Col>
						<Col xl={6}><img src={IMAGES.ctaSeoMasVentas} width={'90%'} style={{marginBottom:'-50px'}}/></Col>
					</Row>
				</Container>
			</div>

			<div className="py-xl-5">
				<Container className="py-xl-4">
					<Row className="text-center">
						<Col xl={6} xs={12} className="align-self-center mx-auto">
							<h2 className="montserrat h-35 color-22 text-center w-600">SEO Marketing services
							<span className="lato h-45 h-tittle block w-800"> for companies in Maryland</span></h2>
							<Divider width="70%" color='#20D9E4' height="7px" clas="mx-xs-auto mx-auto"/>
							<p className="t-18 montserrat color-36 w-400 mt-xl-4 mb-xl-4">We make it easy for you to appear as the <b>firts result on Google</b></p>
						</Col>
					</Row>
					<Row className=" justify-content-between">
						{
							SeoServices.map((service) => {
								return(
									<SEOService {...service} />
								)
							})
						}
					</Row>
				</Container>
			</div>

			<div className="why-choose py-xl-5 py-3 px-4">
				<Container className="py-xl-4 px-2 py-3">
					<Row>
						<Col xl={6} className="px-xl-5">
							<h2 className="montserrat h-35 color-22 w-600 h-sm-20">Why choose our
							<span className="lato h-45 h-tittle block w-800 h-sm-25"> Local SEO Company in Maryland?</span></h2>
							<p className="t-14 montserrat color-36 w-400 bold mt-xl-2 mb-xl-4">#SEO Company Near Me #Best Local SEO Company #Digital SEO Marketing</p>
							<Divider width="70%" color='#20D9E4' height="7px" clas=""/>
							<p className="color-36 t-14 montserrat pt-xl-4 pe-xl-5">We’re the best option because our services are based on studies and strategies, rather than just design. As a result, we can show results and create lasting plans.</p>
							<h3 className="montserrat h-20 color-62 w-800">Quick Fact from a Local SEO Company!</h3>
							<p className="color-36 t-14 montserrat pt-xl-2 pe-xl-5">Many start-ups hire freelancers with web design and programming knowledge to save on their web development projects. However, these two aspects of web development are incredibly different. Not only that, but the content-creation element, which requires experience, is often missing.</p>
							<p className="color-36 t-14 montserrat pt-xl-3 pe-xl-5">Web designers know about web design but understand very little about programming, a skill that is essential for positioning your website. On the other hand, programmers know more about coding than design strategies. An unbalance in web development skills is why so many internet pages look alike, creating problems for a company’s branding and growth.</p>
							<p className="color-36 h-20 montserrat pt-xl-1 pe-xl-5 bold t-sm-14">Call us today for a free consultation!</p>
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2 w-600">240-593-6567</a>
							<Row className="my-5 my-xl-0">
								<Col xl={11} className="px-xl-4 why-keep mt-xl-5">
									<p className="color-36 t-14 montserrat pt-3 px-xl-4 px-2 w-500 t-sm-12">Keep in mind that while it may seem very appealing to pay less and obtain a freelancer’s work, the investment tends never to be recovered and can be risky if the freelancer can’t be kept accountable.</p>
								</Col>
							</Row>
						</Col>
						<Col xl={6} xs={12} className="px-0 px-xl-4">
							<img src={IMAGES.websiteSeo3} loading="lazy" width={'95%'} />
						</Col>
					</Row>
				</Container>
			</div>

			<div className="how-we-help py-5">
				<Container className="py-xl-5">
					<Row className="pe-xl-5">
						<Col xl={6} xs={12} className="pe-xl-5 ps-xl-5">
						<h2 className="lato h-50 text-white h-sm-20 mb-0">Here Is How We Help You Boost Your Business with
						<span className="w-800"> Local SEO</span></h2>
						<Divider width="85%" color='#20D9E4' height="7px" clas="mx-xs-auto"/>
						</Col>
					</Row>
					<Row className="mt-xl-5 pe-xl-4 mb-xl-3">
						{HowWeHelp.map((data) => {
							return(
								<HowWeHelpCard {...data} />
							)
						})}
					</Row>
				</Container>
			</div>

			<div className="cta-purple py-xl-5">
				<img src={IMAGES.logoTrama} width={'80%'} className="logo-overall"/>
				<Container className="py-xl-5">
					<Row>
						<Col xl={5}>
						<p className="lato h-30 w-800 text-white">QA Digital Advertising: <span className="color-56 block">Your Local SEO Powerhouse</span></p>
						</Col>
						<Col xl={5} className="ps-xl-3 border-left-white">
						<p className="text-white t-14 montserrat">Situated in Glen Burnie, and Silver Spring, Maryland, QA Digital Advertising specializes in elevating businesses of all sizes. In today's digital age, even the most captivating websites can get lost in the vast online crowd. That's where we come in. Our dedicated team of SEO experts ensures your business stands out, driving quality traffic right to your doorstep.</p>
						</Col>
					</Row>
				</Container>
			</div>
			
			<div className="py-xl-5">
				<Container className="px-3 py-xl-5">
					<Row className="pb-xl-4 pb-3 px-3 px-xl-0">
						<Col xl={6} className="px-0">
							<h3 className="lato h-35 h-sm-25 color-22 w-600">Let's elevate your business to
							<span className="h-tittle h-45 block w-800"> new heights in the digital world</span>
							</h3>
							<Divider width="70%" color='#20D9E4' height="6px" />
						</Col>
					</Row>
					<Row className="p-xl-5 p-3">
						<YoutubeEmbed embedId="UjhcOAu9n8w?si=JcfhsuFd9ObK7K8T"/>
					</Row>
				</Container>
			</div>

			<div className="cta-seo-2">
				<Container>
					<Row>
						<Col xl={6} className="px-5 align-self-center py-5">
							<h3 className="color-22 h-35 montserrat w-600 pt-4">SO WHAT IS THE
							<span className="h-45 lato color-6f w-800 block"> BEST CHOICE?</span></h3>
							<p className="color-36 montserrat t-14 py-xl-4">The best choice is to contract a company specializing in SEO, programming, and web design—a company with professionals in each aspect that makes up Webdesign. The upfront cost will be repaid by the knowledge and experience that comes from having an entire team back your company’s online growth.</p>
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2 w-600 mb-4">Call us today for a free consultation!</a>
						</Col>
						<Col xl={6} className="p-2"><img src={IMAGES.bestChoice} width={'90%'} className="p-5"/></Col>
					</Row>
				</Container>
			</div>

			<div className="py-xl-4">
				<Container>
					<Row>
						<Col xl={5} className="px-5 align-self-center py-5">
							<h3 className="color-22 h-35 montserrat w-600 pt-4">We give our clients
							<span className="h-45 lato h-tittle w-800 block"> Outstanding Results!</span></h3>
							<Divider width="50%" color='#20D9E4' height="4px" />
						</Col>
					</Row>
					<Row>
						{SeoResults.map((result) => {
							return(
								<SeoResultsCard {...result} />
							)
						})}
					</Row>
					<Row className="justify-content-center py-5 text-center">
						<Col xl={3} xs={10}className="px-4">
							<Link to={"/portfolio"} className="port-button text-white montserrat px-4 bg-grad-purple h-20 t-sm-14 mx-xs-auto">Look at Our Portfolio</Link>
						</Col>
					</Row>
				</Container>
			</div>

			<Formfooter 
				bgColor={'bg-seo-contact'} 
				iconColor={"text-white"} 
				areaColor={"color-7E"} 
				serveColor={"text-white"} 
				colorCity={'text-white'} 
				erveryColor={"color-7E"}
				bgForm={'bg-white'}
				colorLabel={"color-6c"}
				colorInput={"color-6c grey-border"}
				colorStroke={"t-stroke-color-ff"}
				strokeText={"CROW"} />
			
			<div className="py-xl-4">
				<Container className="py-xl-5">
					<Row>
						<Col xl={6}>
							<img src={IMAGES.googleTech} width={'100%'} loading="lazy" className="px-3 py-5"/>
						</Col>
						<Col xl={6} className="pe-5">
							<h3 className="color-22 h-35 montserrat w-600 py-4">What are the benefits of appearing first on
							<span className="h-45 lato h-tittle w-800 block"> Google with local SEO techniques</span></h3>

							<h3 className="montserrat h-20 color-62 w-800">Increased sales</h3>
							<Divider width="15%" color='#20D9E4' height="6px" clas="mb-3"/>
							<p className="t-14 montserrat color-36">This is the most notable and satisfactory result for any business owner since appearing first in local Google search results increases the chances of converting new clients.</p>

							<h3 className="montserrat h-20 color-62 w-800">Branding</h3>
							<Divider width="15%" color='#20D9E4' height="6px" clas="mb-3"/>
							<p className="t-14 montserrat color-36">Remember that the first step in a person’s purchase process is investigating the product or service. This person will purchase within weeks or months if they’ve been constantly exposed to your ads. This is why branding is vital. If your clients don’t remember you, they’ll probably forget about you and decide to shop with someone else.</p>

							<h3 className="montserrat h-20 color-62 w-800">Increase your reach</h3>
							<Divider width="15%" color='#20D9E4' height="6px" clas="mb-3"/>
							<p className="t-14 montserrat color-36">Once the efforts of local SEO positioning start to give results, you’ll notice a considerable increase in calls. By having an established database of potential clients, you’ll be able to market and get a second chance to convince them that your products or services are superior to your competition– thereby increasing your business sales.</p>

							<p className="color-36 h-20 montserrat pt-xl-1 pe-xl-5 bold t-sm-14">Call us today for a free consultation!</p>
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2 w-600 mb-4">240-593-6567</a>
						</Col>
					</Row>
				</Container>
			</div>

			<TeamSection />

			<ReviewSection />

			<div className="py-xl-5">
				<Container>
					<Row>
						<Col xl={6} className="align-self-center">
							<h3 className="color-22 h-35 montserrat w-600 pt-4 pb-2">What is SEO, and why is
							<span className="h-45 lato h-tittle w-800 block"> Local SEO essential?</span></h3>
							<Divider width="80%" color='#20D9E4' height="6px" clas="mb-3"/>
							<p className="t-14 montserrat color-36">SEARCH ENGINE OPTIMIZATION OR SEO , as it’s commonly known, is analyzing different keywords based on how people search in a particular region and implementing them properly throughout your website.</p>
							<p className="t-14 montserrat color-36">Local SEO is vital for the growth of a business since the whole world isn’t the same when it comes to searching. If we know how our potential clients will look for us, we have a greater chance of appearing as their first results, increasing sales.</p>
						</Col>
						<Col>
							<img src={IMAGES.seoLocal} width={'90%'} loading="lazy" className="px-3 pb-xl-4"/>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="cta-seo-2 py-xl-5">
				<Container>
					<Row>
						<Col>
							<img src={IMAGES.seoImportant} width={'100%'} loading="lazy" className="px-3 pb-xl-4"/>
						</Col>
						<Col xl={7} className="py-xl-4 align-self-center pe-xl-5">
							<h3 className="color-22 h-35 montserrat w-600 pt-4 pb-2">Why is SEO
							<span className="h-45 lato color-6f w-800 block"> IMPORTANT?</span></h3>
							<Divider width="70%" color='#ffffff' height="7px" clas="mb-3"/>

							<p className="t-14 montserrat color-36 pe-xl-5">In simpler terms: SEO helps you show up at the top of Google search results.</p>
							<p className="t-14 montserrat color-36 pe-xl-5">Successful businesses use monthly SEO plans to keep appearing at the top. This helps them stay ahead of new businesses. But don’t worry!</p>
							<p className="t-14 montserrat color-36 pe-xl-5">Google knows that new businesses can be great too and gives them a chance to appear at the top. But this chance won’t last forever. If Google checks your website and finds it’s not SEO optimized, they’ll stop showing you in search results. However, if your website is optimized, you’ll have a better chance of showing up at the top, pay less for ads, and compete with top companies in your field.</p>
							
							<p className="color-36 h-20 montserrat pt-xl-1 pe-xl-5 bold t-sm-14">Call us today for a free consultation!</p>
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2 w-600 mb-4">240-593-6567</a>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="py-xl-5">
				<Container>
					<Row>
						<Col xl={6} className="py-xl-4 align-self-center pe-xl-5">
							<h3 className="color-22 h-35 montserrat w-600 pt-4 pb-2">How long does
							<span className="h-40 lato h-tittle w-800 block"> SEO take?</span></h3>
							<Divider width="70%" color='#20D9E4' height="7px" clas="mb-3"/>

							<p className="t-14 montserrat color-36 pe-xl-5">Unfortunately, the answer to this can vary a great deal depending on factors such as the industry in which you are competing, the amount of content you are showing, how many visits your website receives, among other things. However, on average and based on our experience, our experts can help many businesses appear among the top results on Google in 3 to 6 months, just by focusing on organic SEO strategies.</p>
							
							<p className="color-36 h-20 montserrat pt-xl-1 pe-xl-5 bold t-sm-14">Call us today for a free consultation!</p>
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2 w-600 mb-4">240-593-6567</a>
						</Col>
						<Col xl={6}>
							<img src={IMAGES.websiteSeo4} width={'83%'} loading="lazy" className="px-3 py-xl-4"/>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="why-choose py-xl-5 py-3 px-4">
				<Container className="py-xl-5 px-2 py-3">
					<Row>
						<Col xl={6} xs={12} className="px-0 px-xl-4 text-center">
							<img src={IMAGES.teamSeo} loading="lazy" width={'100%'} className="px-xl-4"/>
						</Col>
						<Col xl={6} className="pe-xl-5 align-self-center">
							<h2 className="montserrat h-35 color-22 w-600 h-sm-20 lh-12 mb-4">What can we expect<br /> of QA Digital if
							<span className="lato h-40 h-tittle block w-800 h-sm-25 lh-11"> we start a local SEO strategy?</span></h2>

							<p className="color-36 t-14 montserrat pt-xl-4 pe-xl-5 mb-2">You can expect to see a complete redesign of your website along with content optimization to help you stand apart from your competition and increase your monthly traffic.</p>
							<p className="color-36 t-14 montserrat pt-xl-2 pe-xl-5">Keep in mind that this work isn’t done by one person alone. We have an entire development team behind your project. Our local SEO optimization projects are handled by a group of 4: a project manager, an SEO expert, a web designer, and a web programmer. This combination ensures we don’t leave any loose ends and always offer quality services at a reasonable price.</p>

							<p className="color-36 h-20 montserrat pt-xl-1 pe-xl-5 bold t-sm-14">Call us today for a free consultation!</p>
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple-2 w-600">240-593-6567</a>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="py-xl-5">
				<Container className="py-xl-4">
					<Row>
						<Col xl={6} className="mx-auto">
							<h2 className="h-35 montserrat w-900 text-center support-QA">What support does QA Digital offer to their clients?</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<p className="color-36 t-14 montserrat pt-xl-4 pe-xl-5 text-justify">At QA Digital, we provide extensive support to our clients throughout their project. When you become a QA Digital client, you are assigned a dedicated account manager who will be your main point of contact. They will work closely with you, ensuring effective communication and keeping our team on track.</p>
							<p className="color-36 t-14 montserrat pt-xl-2 pe-xl-5 text-justify">Our team comprises over 180 experts specializing in various areas such as SEO, PPC, web design, and development. With a proven track record of success, we have helped over 600 clients achieve their goals. Our expertise and experience enable us to deliver consistent results across all our clients.</p>
							<p className="color-36 t-14 montserrat pt-xl-2 pe-xl-5 text-justify">We understand that maintaining a successful online presence requires ongoing efforts. That’s why our services don’t end with completing your project. We offer ongoing maintenance and support to ensure your website remains up-to-date and optimized for maximum performance.</p>
						</Col>
						<Col>
							<p className="color-36 t-14 montserrat pt-xl-4 ps-xl-5 text-justify">In addition to web design and development, we specialize in digital marketing. We can launch PPC, email, and social media marketing campaigns depending on your specific needs. These campaigns are designed to drive high-targeted traffic to your website and maximize conversions.</p>
							<p className="color-36 t-14 montserrat pt-xl-2 ps-xl-5 text-justify">At QA Digital, we take pride in our comprehensive approach towards client support. From the initial consultation to ongoing maintenance and digital marketing campaigns, we are committed to providing world-class services that help your business thrive online.</p>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="pb-xl-5">
				<Container className="px-xl-3 pb-xl-5">
					<Row className="pb-xl-4 pb-3 px-3 px-xl-0">
						<Col xl={10} className="px-0">
							<h2 className="montserrat h-35 color-22 w-600 h-sm-20 lh-12 mb-4">Transform Your Business with QA Digital Advertising:
							<span className="lato h-40 h-tittle block w-800 h-sm-25 lh-11">Your Local SEO & Web Design Experts</span></h2>
							<Divider width="40%" color='#20D9E4' height="7px" />
						</Col>
					</Row>
					<Row className="px-xl-5 p-3">
						<YoutubeEmbed embedId="Fr_Bj51yhkc?si=pT2XT32hXPvCKUnM"/>
					</Row>
				</Container>
			</div>

			<div className="p-100">
				<Container className="our-mission bg-color-7b ps-5 pb-5 pt-3">
					<Row className="ps-3">
						<Col xl={6} className="pe-xl-5 align-self-center">
						<h2 className="h-35 text-white montserrat bold">Our
						<span className="h-40 bg-text-mission lato w-800"> Mission</span></h2>
						<Divider width="80%" color='#20D9E4' height="7px" />
						<p className="text-white t-14 montserrat pt-xl-4 pe-xl-5">Our team is focused on helping you appear among the top results in Google. To do this, we analyze your competition and note their strengths and weaknesses. This way, we can surpass any publicity effort your competition is using and guarantee that you are the best in your industry with no problem.</p>
						<p className="text-white t-14 montserrat pt-xl-0 pe-xl-5">Are you ready to show up <b className="color-20 ">first in a local Google search?</b></p>
						<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-blue w-600">240-593-6567</a>
						</Col>
						<Col xl={6} className="negative-m-100 text-center">
						<img src={IMAGES.alfonsoSeo} width={'85%'} className="seo-keywords-img" loading="lazy"/>
						</Col>
					</Row>
				</Container>
			</div>

		</>
	);
};

export default SEO;