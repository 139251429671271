import mistyLogo from "./assets/logo-misty.svg"
import invisualLogo from "./assets/Invisual-logo.svg"
import bmaLogo from "./assets/bma-logo.svg"
import eastPointLogo from "./assets/eastpoint-logo.svg"
import uRelaxLogo from "./assets/urelax-logo.svg"
import gwLogo from "./assets/GW-logo.svg"
import bizerbaLogo from "./assets/bizerba-logo.svg"
import phonewhite from "./assets/phonewhite.svg"
import envelop from "./assets/envelop.svg"
import mapWhite from "./assets/mapWhite.svg"
import starReview from "./assets/star-review.svg"

const IMAGES = {
    logoqa : new URL('./assets/LOGO QA.png', import.meta.url).href,
    bannerQaHome : new URL('./assets/Banner-Home-QA.webp', import.meta.url).href,
    bannerQaHomeMockup: new URL('./assets/MOCK-UP-BANNER-RESPONSIVE-1.webp', import.meta.url).href,
    mapAlt: new URL('./assets/map-alt.png', import.meta.url).href,
    phoneAlt: new URL('./assets/phone-alt.png', import.meta.url).href,
    mistyLogo: mistyLogo,
    invisualLogo: invisualLogo,
    bmaLogo: bmaLogo,
    eastPointLogo: eastPointLogo,
    uRelaxLogo: uRelaxLogo,
    gwLogo: gwLogo,
    bizerbaLogo: bizerbaLogo,
    websiteServiceIcon: new URL('./assets/website-services.webp', import.meta.url).href,
    seoIcon : new URL('./assets/home-seo-icon.webp', import.meta.url).href,
    bmaMOck: new URL('./assets/bmalandscaping-mockup.webp', import.meta.url).href,
    wdcttMock: new URL('./assets/wdctt-mockup.webp', import.meta.url).href,
    safeEgressMock: new URL('./assets/safeegress-mockup.webp', import.meta.url).href,
    ltaFlightMock: new URL('./assets/ltaflightMockup1.png', import.meta.url).href,
    csweldingMock: new URL('./assets/cswelding-mockup.webp', import.meta.url).href,
    safeguardMock: new URL('./assets/safeguard-mockup.webp', import.meta.url).href,
    acgMock: new URL('./assets/acg-mockup.webp', import.meta.url).href,
    tagtitleMock : new URL('./assets/tagandtitle-mockup.webp', import.meta.url).href,
    printingHome1 : new URL('./assets/printing-home-1.webp', import.meta.url).href,
    printingHome2 : new URL('./assets/printing-home-2.webp', import.meta.url).href,
    printingHome3 : new URL('./assets/printing-home-3.webp', import.meta.url).href,
    printingHome4 : new URL('./assets/printing-home-4.webp', import.meta.url).href,
    printingHome5 : new URL('./assets/printing-home-5.webp', import.meta.url).href,
    qaReviewCircle : new URL('./assets/qa-review-circle.webp', import.meta.url).href,
    payPal : new URL('./assets/PayPal-Logo-1.webp', import.meta.url).href,
    zelle : new URL('./assets/Zelle_payment_service-Logo-1.webp', import.meta.url).href,
    heartland : new URL('./assets/Heartland-logo.webp', import.meta.url).href,
    square : new URL('./assets/Square_Inc._logo.webp', import.meta.url).href,
    alfonsoQ : new URL('./assets/Alfonso-quinonez.png', import.meta.url).href,
    julianQ : new URL('./assets/Julian-quinonez.png', import.meta.url).href,
    camiloR : new URL('./assets/Camilo-Ramos.webp', import.meta.url).href,
    sebastianA : new URL('./assets/Sebastian-Ardila.png', import.meta.url).href,
    dannaM : new URL('./assets/Danna-Meneces.webp', import.meta.url).href,
    estebanA : new URL('./assets/Esteban-Atehortua.png', import.meta.url).href,
    danielR : new URL('./assets/Daniel-Rodriguez.png', import.meta.url).href,
    johanO : new URL('./assets/Johan-Osman.png', import.meta.url).href,
    marianS : new URL('./assets/MarianSalazar.jpg', import.meta.url).href,
    juanD : new URL('./assets/Juan-Camilo.png', import.meta.url).href,
    jorgeI : new URL('./assets/Jorge-D.png', import.meta.url).href,
    wdcttLogo : new URL('./assets/WDCTT-LOGO-1.webp', import.meta.url).href,
    aMaryland : new URL('./assets/LOGO-1A-MARYLAND.webp', import.meta.url).href,
    japanLogo : new URL('./assets/LOGO-JAPAN-PRINT.webp', import.meta.url).href,
    mistyLogoColor : new URL('./assets/LOGO-MISTY-1.webp', import.meta.url).href,
    phoneWhite: phonewhite,
    envelop: envelop,
    mapWhite: mapWhite,
    bgBannerPortfolio: new URL('./assets/BANNER-Portfolio.webp', import.meta.url).href,
    imageBannerPortfolio: new URL('./assets/paginas-web-profesionales.webp', import.meta.url).href,
    boostImage1: new URL('./assets/give-power-to-your-brand.webp', import.meta.url).href,
    boostImage2: new URL('./assets/Group-77.webp', import.meta.url).href,
    port1: new URL('./assets/lta-flight-magazine-port.webp', import.meta.url).href,
    port2: new URL('./assets/sofia-fashion-port.webp', import.meta.url).href,
    port3: new URL('./assets/rodent-proof-crew-port.webp', import.meta.url).href,
    port4: new URL('./assets/I-can-do-it-port.webp', import.meta.url).href,
    port5: new URL('./assets/attic-repair-port.webp', import.meta.url).href,
    port6: new URL('./assets/kpk-enterprice-port.webp', import.meta.url).href,
    port7: new URL('./assets/maryland-landscape-port.webp', import.meta.url).href,
    port8: new URL('./assets/irma-cleaning-port.webp', import.meta.url).href,
    port9: new URL('./assets/jr-roofing-port.webp', import.meta.url).href,
    port10: new URL('./assets/air-quiality-technology-port.webp', import.meta.url).href,
    port11: new URL('./assets/karina-cleaning-port.webp', import.meta.url).href,
    port12: new URL('./assets/cephius-real-state-port.webp', import.meta.url).href,
    port13: new URL('./assets/temple-church-building-port.webp', import.meta.url).href,
    port14: new URL('./assets/galvan-tree-experts-port.webp', import.meta.url).href,
    port15: new URL('./assets/fd-auto-repair-port.webp', import.meta.url).href,
    port16: new URL('./assets/abundant-life-family-church-port.webp', import.meta.url).href,
    port17: new URL('./assets/u-relax-moving-port.webp', import.meta.url).href,
    port18: new URL('./assets/pool-pros-port.webp', import.meta.url).href,
    port19: new URL('./assets/invisual-signs-port.webp', import.meta.url).href,
    port20: new URL('./assets/eagle-national-movers-port.webp', import.meta.url).href,
    port21: new URL('./assets/bm-express-port.webp', import.meta.url).href,
    port22: new URL('./assets/east-point-port.webp', import.meta.url).href,
    port23: new URL('./assets/adar-court-report-port.webp', import.meta.url).href,
    port24: new URL('./assets/whitin-hypnosys-port.webp', import.meta.url).href,
    redirectToSEO: new URL('./assets/redirect-your-budget-to-SEO.webp', import.meta.url).href,
    bmaLogoColor: new URL('./assets/bma-logo-color.webp', import.meta.url).href,
    habibLogo: new URL('./assets/Logo-Habib.webp', import.meta.url).href,
    realClientsSEO1: new URL('./assets/website-seo-real-clients.webp', import.meta.url).href,
    findWithUs: new URL('./assets/With-us-you-can-find.webp', import.meta.url).href,
    seoKeywords: new URL('./assets/man-working-night-1.webp', import.meta.url).href,
    websiteSeo2: new URL('./assets/website-seo-2.webp', import.meta.url).href,
    rectangule443: new URL('./assets/Rectangle-344.webp', import.meta.url).href,
    howQAHelp: new URL('./assets/how-qa-help.webp', import.meta.url).href,
    ctaSeoMasVentas: new URL('./assets/cta-seo-mas-ventas-img.webp', import.meta.url).href,
    starReview: starReview,
    websiteSeo3: new URL('./assets/website-seo-3.webp', import.meta.url).href,
    logoTrama: new URL('./assets/logo-frame.webp', import.meta.url).href,
    bestChoice: new URL('./assets/el-mejor-camino-img.webp', import.meta.url).href,
    towerPavigMock: new URL('./assets/toweMockup.webp', import.meta.url).href,
    acgllcMock1: new URL('./assets/acgllcMock1.webp', import.meta.url).href,
    googleTech: new URL('./assets/google-tecnica-img.webp', import.meta.url).href,
    seoLocal: new URL('./assets/seo-local.webp', import.meta.url).href,
    seoImportant: new URL('./assets/SEO-IMPORTANTE-IMG.webp', import.meta.url).href,
    websiteSeo4: new URL('./assets/website-seo-4.webp', import.meta.url).href,
    teamSeo: new URL('./assets/team-seo.png', import.meta.url).href,
    alfonsoSeo: new URL('./assets/alfonso-img-seo.webp', import.meta.url).href,
    webDesignCompany: new URL('./assets/web-design-company.webp', import.meta.url).href,
    whyUsWebDesign: new URL('./assets/why-us-img-web-design.webp', import.meta.url).href,
    competitiveIcon: new URL('./assets/analysis-icon.webp', import.meta.url).href,
    profesionalIcon: new URL('./assets/web-design-icon.webp', import.meta.url).href,
    contentIcon: new URL('./assets/content-creation-icon.webp', import.meta.url).href,
    codingIcon: new URL('./assets/clean-coding-icon.webp', import.meta.url).href,
    responsiveIcon: new URL('./assets/responsive-icon.webp', import.meta.url).href,
    builSeoIcon: new URL('./assets/built-for-seo-icon.webp', import.meta.url).href,
    qaWhyUsWebDesign: new URL('./assets/QA-Digital-Ads-Why-Us-image.webp', import.meta.url).href,
    businessSection: new URL('./assets/Web-design-Business-section-girl.webp', import.meta.url).href,
    believeWomen: new URL('./assets/Web-Design-Belive-Section-Woman-Image.webp', import.meta.url).href,
    pageComputer: new URL('./assets/QA-Web-Design-Page-Computer-image.webp', import.meta.url).href,
    standingWomen: new URL('./assets/QA-Web-Design-Page-Standing-women-image.webp', import.meta.url).href,
    googleImage: new URL('./assets/QA-Web-Design-Page-Google-image.webp', import.meta.url).href,
    analiticsImage: new URL('./assets/QA-Web-Design-Page-analitycs-image.webp', import.meta.url).href,
    manWithLaptop: new URL('./assets/QA-Web-Design-Page-Man-with-laptop.webp', import.meta.url).href,
    
}

export default IMAGES;