import { Row, Col, Container } from "react-bootstrap";
import Divider from "./Divider";
import TeamMember from "./TeamMember";
import { TeamMembersData } from "../data";
import { useState } from "react";

const TeamSection = () => {

	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [hovering, setHovering] = useState(false);
  
	const handleMouseMove = (e) => {
	  const rect = e.currentTarget.getBoundingClientRect();
	  setPosition({
		x: e.clientX - rect.left,
		y: e.clientY - rect.top,
	  });
	};
  
	const handleMouseEnter = () => {
	  setHovering(true);
	  console.log(hovering)
	};
  
	const handleMouseLeave = () => {
	  setHovering(false);

	};
	
    return (
        <>

        	<div className="team-sec py-5" onMouseMove={handleMouseMove} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
				<Container>
					<Row className="text-center pb-5 px-5 px-xl-3">
					<h3 className="montserrat h-40 h-sm-30 text-white bold">YOUR<br/>
						<span className="valorant t-stroke h-sm-50 h-100">TEAM</span>
						<Divider width="70%" color='#ffffff' height="1px" clas="my-0 mx-auto"/>
					</h3>
					<h4 className="montserrat text-white text-center t-sm-14 px-5 px-xl-3">MEET THE PEOPLE YOU WILL BE WORKING WITH!</h4>
					</Row>
					<Row>
						{TeamMembersData.map((member) => {
							return (
								<TeamMember {...member} key={member.name}/>
							)
						})}
					</Row>
				</Container>
				<div
          className={`circle ${hovering ? "circle-grow" : "circle-shrink"}`}
          style={{ left: `${position.x}px`, top: `${position.y}px` }}
        >
          <span
            className={`circle-text lato h-20 bold text-center ${
              hovering ? "text-appear" : "text-disappear"
            }`}
          >
            Hover Picture
          </span>
        </div>

			</div>
        </>
    )
}

export default TeamSection;