import React from "react";
import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import IMAGES from "../Images";
import AddressPhone from "../Components/AddressPhone";
import Divider from "../Components/Divider";
import ServicesCard from "../Components/Services";
import { ServicesInfo, ProjectsInfo, TeamMembersData, areasServe } from "../data";
import ProjectsCard from "../Components/Projects";
import { Link } from "react-router-dom";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Formfooter from "../Components/FormFooter"
import TeamSection from "../Components/TeamSection";
import ReviewSection from "../Components/ReviewsSection";
import YoutubeEmbedLazy from "../Components/LazyLoadedYoutubeVideo";



const Home = () => {

	const [index, setIndex] = useState(-1);

	const handleClick = (index) => setIndex(index);

	return (
		<>
			<div className="home-bg">
				<Container>
					<Row className="d-flex">
						<Col xxl={5} xs={12} sm={12} className="order-1 order-sm-0">
							<img src={IMAGES.bannerQaHomeMockup} width={"100%"} loading="lazy" className="py-xl-5 pt-3 pb-4"></img>
						</Col>
						<Col xxl={7} sm={12} xs={12} className="ps-xl-5 order-md-0 order-xl-0 order-sm-0 pt-4 pt-xl-5">
							<h1 className="pt-5 h1BH h-45 h-sm-35 montserrat text-white text-sm-center text-xl-start">
								{/* aqui estoy colocando un comentario YunT*/}
								WE ARE <br /><span className="text-linear h-sm-45">THE BEST</span><br /> WEB DESIGN COMPANY IN MARYLAND!</h1>
							<Row>
								<Col xxl={12}>
									<h3 className="text-white kumbh-sans bold pt-xl-5 pt-4 text-sm-center text-xl-start">VISIT US</h3>
									<Divider width="70%" color='#09F2F2' height="3px" clas="mx-xs-auto" />
								</Col>
								<Col xxl={6} className="pt-4">
									<AddressPhone image={IMAGES.mapAlt} href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x89b7ddca8bcfe903:0x5982abeea75887d5?entry=s&sa=X&hl=en-us&ved=2ahUKEwjM9q25yOX5AhVDFlkFHSwEDFoQ4kB6BAgVEAI" classes="a-address-tel" whereTo="_blank">706 Crain Highway North, Suite A Glen Burnie, MD 21061</AddressPhone>
									<AddressPhone image={IMAGES.mapAlt} href="https://goo.gl/maps/ojnpLdxN4A3UDm1v7" classes="a-address-tel" whereTo="_blank">817 Silver Spring Ave. Silver Spring, MD 20910</AddressPhone>
								</Col>
								<Col xxl={6} className="pt-4">
									<AddressPhone image={IMAGES.phoneAlt} href='tel:+1240-593-6567' classes={'a-address-tel'} whereTo="_self">240-593-6567 (Eng.)</AddressPhone>
									<AddressPhone image={IMAGES.phoneAlt} href='tel:+1240-521-8700' classes={"a-address-tel"} whereTo="_self">240-521-8700 (Spa.)</AddressPhone>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="logos-bg">
				<Container>
					<Row className="py-4">
						<Col xs={6} xl={2} className="align-self-center text-center">
							<img src={IMAGES.mistyLogo} className="white-svg" width={'70%'}></img>
						</Col>
						<Col xs={6} xl={2} className="align-self-center text-center">
							<img src={IMAGES.invisualLogo} className="white-svg" width={'70%'}></img>
						</Col>
						<Col xs={6} xl={2} className="align-self-center text-center">
							<img src={IMAGES.bmaLogo} className="white-svg " width={'70%'}></img>
						</Col>
						<Col xs={6} xl={2} className="align-self-center text-center">
							<img src={IMAGES.bizerbaLogo} className="white-svg" width={'70%'}></img>
						</Col>
						<Col xs={6} xl={2} className="align-self-center text-center">
							<img src={IMAGES.eastPointLogo} className="white-svg" width={'40%'}></img>
						</Col>
						<Col xs={6} xl={2} className="align-self-center text-center">
							<img src={IMAGES.gwLogo} className="white-svg" width={'40%'}></img>
						</Col>
					</Row>
				</Container>
			</div> 

			<div className="py-3 py-xl-5">
				<Container className="py-5">
					<Row className="pb-4 ps-3 ps-xl-3">
						<Col xxl={3} className="px-0">
							<h3 className="lato h-tittle h-sm-30">
								OUR SERVICES
							</h3>
						</Col>
						<Col xxl={9} className="align-self-center ps-0 ps-xl-3">
							<Divider width="73%" color='#20D9E4' height="7px"/>
						</Col>
					</Row>
					<Row className="pt-3 pt-xl-0">
						<ServicesCard marg="me-3 mb-4 mb-xl-3 width-49" key={ServicesInfo.title} {...ServicesInfo[0]} imgHeight={60}/>
						<ServicesCard marg="ms-2 mb-4 mb-xl-3 width-49" key={ServicesInfo.title} {...ServicesInfo[1]} imgHeight={60}/>
					</Row>
				</Container>
			</div> 

			<div className="recent-projects">
				<Container className="py-5">
					<Row className="pb-4 px-3 px-xl-0">
						<Col xxl={4} className="px-3 px-xl-0">
							<h3 className="lato text-center text-xl-start">Some of our <br />
								<span className="h-tittle h-45 h-sm-30">RECENT PROJECTS</span>
							</h3>
							<Divider width="280px" color='#20D9E4' height="4px" clas="mx-xs-auto" />
						</Col>
					</Row>
					<Row className="py-4">
						<ProjectsCard {...ProjectsInfo[0]} key={ProjectsInfo[0].title}/>
						<ProjectsCard {...ProjectsInfo[1]} key={ProjectsInfo[1].title}/>
						<ProjectsCard {...ProjectsInfo[2]} key={ProjectsInfo[2].title}/>
						<ProjectsCard {...ProjectsInfo[3]} key={ProjectsInfo[3].title}/>
					</Row>
					<Row>
						<ProjectsCard {...ProjectsInfo[4]} key={ProjectsInfo[4].title}/>
						<ProjectsCard {...ProjectsInfo[5]} key={ProjectsInfo[5].title}/>
						<ProjectsCard {...ProjectsInfo[6]} key={ProjectsInfo[6].title}/>
						<ProjectsCard {...ProjectsInfo[7]} key={ProjectsInfo[7].title}/>
					</Row>
					<Row className="justify-content-center pt-5 text-center">
						<Col xl={3} xs={10}className="px-4">
							<Link to={"/portfolio"} className="port-button text-white montserrat px-4 bg-grad-purple h-20 t-sm-14 mx-xs-auto">SEE FULL PORTFOLIO</Link>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="py-xl-5 px-3 py-3">
				<Container className="py-5">
					<Row className="pb-4">
						<Col xxl={4} className="px-0">
							<h3 className="lato h-35 h-sm-20 text-center text-xl-start">OUR <br />
								<span className="h-tittle h-45 h-sm-35">PRINTING SERVICES</span>
							</h3>
							<Divider width="80%" color='#20D9E4' height="4px" clas="mx-xs-auto"/>
						</Col>
					</Row>
					<Row className="px-xl-3 px-0">
						<Col xl={5} xs={12} className="align-self-center px-0">
							<img src={IMAGES.printingHome1} width={'100%'} onClick={() => handleClick(0)} loading="lazy"></img>
						</Col>
						<Col  className="align-self-center ps-xl-4 pe-xl-4 py-3 px-0">
							<img src={IMAGES.printingHome2} width={'100%'} onClick={() => handleClick(1)} loading="lazy"></img>
						</Col>
						<Col className="align-self-center ps-xl-3 py-3 px-0 ">
							<img src={IMAGES.printingHome3} width={'100%'} onClick={() => handleClick(2)} loading="lazy"></img>
						</Col>
					</Row>
					<Row className="px-xl-3 pt-xl-4">
						<Col xl={5} xs={12} className="align-self-center px-0">
							<img src={IMAGES.printingHome4} width={'100%'} onClick={() => handleClick(3)} loading="lazy"></img>
						</Col>
						<Col xs={12} xl={7} className="align-self-center ps-xl-4 pe-xl-4 px-0 py-3">
							<img src={IMAGES.printingHome5} width={'100%'} onClick={() => handleClick(4)} loading="lazy"></img>
						</Col>
						<Lightbox styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
							slides={[
								{ src: IMAGES.printingHome1 },
								{ src: IMAGES.printingHome2, width: "100%" },
								{ src: IMAGES.printingHome3, width: "100%" },
								{ src: IMAGES.printingHome4, width: "100%" },
								{ src: IMAGES.printingHome5, width: "100%" },
							  ]}
							open={index >= 0}
							index={index}
							close={() => setIndex(-1)}
						/>						
					</Row>
					<Row className="justify-content-center pt-xl-5 pt-3 text-center">
						<Col xxl={3} className="px-4">
							<Link to={"/#contact"} className="port-button text-white montserrat px-4 bg-grad-purple t-sm-14 h-20">REQUEST A QUOTE</Link>
						</Col>
					</Row>
				</Container>
			</div> 

			<div className="pb-3 pb-xl-5">
				<Container className="py-xl-5 pt-3 pb-5 px-3">
					<Row className="pb-4 px-3">
						<Col xxl={4} className="px-0">
							<h3 className="lato h-35 h-sm-20 text-center text-xl-start">OUR <br />
								<span className="h-tittle h-45 h-sm-30">SEO SERVICES</span>
							</h3>
							<Divider width="80%" color='#20D9E4' height="4px" clas="mx-xs-auto"/>
						</Col>
					</Row>
					<Row className="px-3">
						<YoutubeEmbedLazy embedId="EeoVMKZcc2Y"/>
					</Row> 
				</Container>
			</div>

			<ReviewSection />

			<div className="pay-section">
				<Container>
					<Row className="py-4 d-flex">
						<Col xl={12} className="montserrat text-white t-payment text-center pb-4">Pay From Anywhere Through Our Safe Portals</Col>
						<Col xs={5} xl={3} className="align-self-center text-center order-0 order-xl-0">
							<img src={IMAGES.payPal} width={'100%'} className="pay-icon-paypal" />
						</Col>
						<Col xs={6} xl={3} className="align-self-center text-center order-2 order-xl-1">
							<img src={IMAGES.zelle} width={'80%'} className="pay-icon-zelle" />
						</Col>
						<Col xs={7} xl={3} className="align-self-center text-center order-1 order-xl-2">
							<img src={IMAGES.heartland} width={'100%'} className="pay-icon-height" />
						</Col>
						<Col xs={6} xl={3} className="align-self-center text-center order-3 order-xl-3">
							<img src={IMAGES.square} width={'100%'} className="pay-icon-square" />
						</Col>
					</Row>
				</Container>
			</div> 

			<TeamSection />

			<div>
				<Container className="py-3 py-xl-5">
					<Row className="justify-content-md-center pt-4">
						<Col xxl={8}>
							<h3 className="montserrat h-35 bold text-center t-sm-18">Here are some examples of small businesses that we have helped grow in the past:</h3> 
						</Col>
					</Row>
					<Row className="gap-5 p-4 p-xl-5">
						<Col className="p-4 small-ej text-center">
							<img src={IMAGES.wdcttLogo} width={123}/>
							<p className="m-0 kumbh small-ej-text bold">Minority-own business</p>
						</Col>
						<Col className="p-4 small-ej text-center">
							<img src={IMAGES.aMaryland} width={144}/>
							<p className="m-0 kumbh small-ej-text bold">Registration <br />Woman-owned business</p>
						</Col>
						<Col className="p-4 small-ej text-center">
							<img src={IMAGES.mistyLogoColor} width={167} className="pb-4"/>
							<p className="m-0 kumbh small-ej-text bold">Woman-owned business</p>
						</Col>
						<Col className="p-4 small-ej text-center">
							<img src={IMAGES.japanLogo} width={93}/>
							<p className="m-0 kumbh small-ej-text bold">Minority-owned business</p>
						</Col>
					</Row>
					<Row className="justify-content-center text-center pt-1">
						<Col xxl={3} className="px-4">
							<Link to={"/portfolio"} className="port-button text-white montserrat px-4 bg-grad-purple">SEE FULL PORTFOLIO</Link>
						</Col>
					</Row>
				</Container>
			</div>

			<Formfooter
				bgColor={'bg-white'}
				areaColor={"color-63"} 
				iconColor={"color-AB"} 
				serveColor={"color-63"} 
				colorCity={"color-33"} 
				erveryColor={"color-6f"} 
				bgForm={"bg-color-d9"}
				colorLabel={"color-6f"}
				colorInput={"color-6f white-border"}
				colorStroke={"t-stroke-color-6f"} 
				strokeText={"CONTACT US"}/>
		</>
	);
};

export default Home;