import React from "react";
import { Col, Row } from "react-bootstrap";

const AddressPhone = ({image, href, whereTo, maxWidth=40, classes, colClass="", children}) => {

    return (
        <>
            <Row className="py-1 py-xl-2">
                <Col xxl={2} sm={2} xs={2} className="px-xl-0 align-self-center">
                    <img src={image} width={"100%"} style={{ maxWidth: maxWidth }}></img>
                </Col>
                <Col xxl={10} sm={10} xs={10} className={"ps-0 pe-4 align-self-center "+colClass}>
                    <a href={href} target={whereTo} className={"text-white montserrat align-middle " + classes} >{children}</a>
                </Col>
            </Row>
        </>
    )
}

export default AddressPhone;

