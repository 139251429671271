import React, { useRef } from "react";
import Divider from "./Divider";
import { Row, Col, Container } from "react-bootstrap";
import { areasServe } from "../data";
import emailjs from '@emailjs/browser';

const Formfooter = ({bgColor, iconColor, areaColor, serveColor, colorCity, erveryColor, bgForm, colorLabel, colorInput, colorStroke, strokeText}) => {

    const form = useRef();
    const phone = useRef();
    const errorRef = useRef(null);
    
    const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    const handleChange = () => {
        const input = phone.current;
        // Clear any old status
        input.setCustomValidity('');
      };

    const handleSubmit = (event) => {
        event.preventDefault();

        const input = phone.current;

    // Clear any old status
    input.setCustomValidity('');

    // Check for invalid state(s)
    if (!pattern.test(input.value)) {
      input.setCustomValidity('Please enter a valid phone number');
      input.reportValidity();
    } else {
      input.setCustomValidity('');
      // Proceed with form submission if needed
      /*emailjs
      .sendForm('service_iyjzik5', 'template_7qvsg6f', form.current, {
        publicKey: '25bO-dCc3QTCgWjyH',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );*/


      console.log(form.current);
    }
        
    }

    return (
        <>
            <div className={bgColor} id="contact">
            <Container className="pb-0 pt-5 py-xl-5">
                <Row className="d-flex">
                    <Col xl={6} xs={11} className="area-we-serve order-0 order-xl-0">
                    <Row className="py-4">
                        <Col xxl={8} className="pb-4">
                            <h2><i className={"bi bi-geo-alt i-80 block "+iconColor}></i>
                            <span className={"t-80 bold montserrat h-sm-40 block area " + areaColor}>AREAS </span>
                            <span className={"t-54 bold montserrat h-sm-40 block we-serve "+serveColor}>WE SERVE</span>
                            </h2>
                            <Divider width="270px" color='#20D9E4' height="8px"/>
                        </Col>
                        {areasServe.map((city) => {
                            return(
                                <p className={"pb-1 montserrat t-14 bold city-text ps-3 mb-0 "+colorCity} key={city}>{city}</p>
                            )
                        })}
                        <p className={"montserrat bold t-18 pt-4 "+erveryColor} >& EVERYWHERE IN MARYLAND!</p>
                    </Row>
                    </Col>
                    <Col xl={5} xs={12} className={"form-footer pb-5 order-2 order-xl-1 "+bgForm}>
                        <Row className="justify-content-md-center pt-5 pb-3">
                            <Col xxl={9}>
                                <h2 className="montserrat h-40 bold text-center ff-h h-sm-30 px-5 px-xl-0">LET'S TALK ABOUT <br /><span className="t-stroke small h-45 h-sm-30">YOUR PROJECT</span></h2>
                            </Col>
                        </Row>
                        <Divider width="140px" color='#C9FFF9' height="8px" clas="my-0 mx-auto"/>
                        <form className="px-3 px-xl-5" ref={form} onSubmit={handleSubmit}>
                            <div className="mb-1">
                                <label htmlFor="name" className={"form-label ff-label montserrat bold m-1 "+colorLabel}>NAME</label>
                                <input  type="text" 
                                        className={"form-control custom-input-footer montserrat "+colorInput} 
                                        id="name"  
                                        placeholder="Nice to meet you!"
                                        name="name"
                                        required
                                        />
                            </div>
                            <div className="mb-1">
                                <label htmlFor="phone" className={"form-label ff-label montserrat bold m-1 "+colorLabel}>PHONE</label>
                                <input type="text"
                                        className={"form-control custom-input-footer montserrat "+colorInput}
                                        id="phone"
                                        aria-describedby="emailHelp" 
                                        placeholder="So your ideas don't stay on paper"
                                        name="phone"
                                        ref={phone}
                                        onChange={handleChange}
                                        required
                                        />
                            </div>
                            <p ref={errorRef} style={{ color: 'red' }}></p>
                            <div className="mb-1">
                                <label htmlFor="email" className={"form-label ff-label montserrat bold m-1 "+colorLabel}>EMAIL</label>
                                <input type="email" 
                                        className={"form-control custom-input-footer montserrat "+colorInput}
                                        id="email" 
                                        placeholder="Let's connect"
                                        name="email"
                                        required/>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="comment" className={"form-label ff-label montserrat bold m-1 "+colorLabel}>COMMENT</label>
                                <textarea rows="3" 
                                        type="text" 
                                        className={"form-control custom-input-footer montserrat "+colorInput} 
                                        id="comment" 
                                        placeholder="Is there anything else we should know before contacting you"
                                        name="comment"/>
                            </div>
                            <div className="d-grid">
                                <button className="btn bg-color-6F text-white bg-hover-E3">SEND</button>
                            </div>
                        </form>
                    </Col>
                    <Col xl={1} xs={1} className="order-1 order-xl-2 align-self-center ">
                    <div className="rotate-centered">
                    <p className={"t-stroke contact-us h-75 h-sm-45 text-center text-xl-start "+colorStroke}>{strokeText}</p>
                    </div>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    )
}

export default Formfooter;