import { Col } from "react-bootstrap"

const HowWeHelpCard = ({linetitle1, linetitle2, description}) => {
    return(
        <>
        	<Col xl={6} className="pe-xl-5 ps-xl-5 my-xl-4">
				<p className="text-white montserrat t-18 bold mb-xl-3" style={{lineHeight: "22px"}}>{linetitle1}<br/>{linetitle2}</p>
				<p className="text-white montserrat t-14 text-justify pe-xl-5">{description}</p>
			</Col>
        </>
    )
}

export default  HowWeHelpCard;