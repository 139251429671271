import Home from './Pages/Home';
import SEO from './Pages/SEO';
import Portfolio from './Pages/Portfolio';
import WebsiteDesign from './Pages/WebsiteDesign';

const routes = [
    { path: '/', component: <Home />, exact: true },
    { path: '/seo-local-maryland', component: <SEO /> },
    { path: '/web-design-company-in-maryland', component: <WebsiteDesign /> },
    { path: '/portfolio', component: <Portfolio /> },
  ];
  
  export default routes;