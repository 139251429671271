import { Col, Container, Row } from "react-bootstrap"

const SeoResultsCard = ({title, image, to, description}) => {
    return(
        <>
            <Col xl={4} xs={12} className="align-self-center text-center">
                <a href={to} target="_blank">
                <img src={image} alt="" width={"100%"} loading="lazy"/>
                </a>
                <h3 className="t-18 montserrat color-22 w-500 px-xl-5">{description}</h3>
                <a href={to} target="_blank" className="montserrat project-t t-sm-11 h-20 bold">{title}</a>
            </Col>
        </>
    )
}

export default SeoResultsCard;