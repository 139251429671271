import React, { useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import emailjs from '@emailjs/browser';

const FormBlack = ({textTitle}) => {

    const form = useRef();
    const phone = useRef();
    const errorRef = useRef(null);
    
    const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    const handleChange = () => {
        const input = phone.current;
        // Clear any old status
        input.setCustomValidity('');
      };

    const handleSubmit = (event) => {
        event.preventDefault();

        const input = phone.current;

    // Clear any old status
    input.setCustomValidity('');

    // Check for invalid state(s)
    if (!pattern.test(input.value)) {
      input.setCustomValidity('Please enter a valid phone number');
      input.reportValidity();
    } else {
      input.setCustomValidity('');
      // Proceed with form submission if needed
      /*emailjs
      .sendForm('service_iyjzik5', 'template_7qvsg6f', form.current, {
        publicKey: '25bO-dCc3QTCgWjyH',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );*/


      console.log(form.current);
    }
        
    }

    return (
        <>
        <div className="form-black mx-3 mx-xl-0 rounded-sm-border nm-top-sm-50">
				<Container className="py-4 ">
					<h3 className="text-white h-20 lato w-800 ps-2 ps-xl-5 t-sm-14 text-center text-xl-start">{textTitle}</h3>
					<form className="pt-3" ref={form} onSubmit={handleSubmit}>
						<Row className="px-3 px-xl-5 pb-4">
							<Col className="px-1 input-form-black mb-2 mb-xl-0">
								<div className="mb-1">
									<input  type="text" 
											className="form-control custom-input-black montserrat py-2" 
											id="name"  
											placeholder="Name"
											name="name"
											required
											/>
								</div>
							</Col>
              <Col className="px-1 input-form-black mb-2 mb-xl-0">
                <div className="mb-1">
                  <input type="text"
                    className="form-control custom-input-black montserrat py-2" 
                    id="phone"
                    aria-describedby="emailHelp" 
                    placeholder="Phone"
                    name="phone"
                    ref={phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <p ref={errorRef} className="mb-0" style={{ color: 'red' }}></p>
              </Col>
              <Col className="px-1 input-form-black mb-2 mb-xl-0">
                <div className="mb-1">
                  <input type="email" 
                    className="form-control custom-input-black montserrat py-2" 
                    id="email" 
                    placeholder="Email"
                    name="email"
                    required/>
                </div>
              </Col>
              <Col className="px-1 input-form-black mb-3 mb-xl-0">
                <div className="mb-1">
                  <input rows="1" 
                    type="text" 
                    className="form-control custom-input-black montserrat py-2" 
                    id="comment" 
                    placeholder="Message"
                    name="comment"/>
                </div>
              </Col>
              <Col xs={12} xl={1} >
                <div className="d-grid">
                  <button className="btn btn-black valorant">SEND</button>
                </div>
              </Col>
						</Row>
          </form>
				</Container>
			</div>
      </>
    )
}

export default FormBlack;