import { Col, Container, Row } from "react-bootstrap"
import ReviewIndividual from "./ReviewIndividual"
import Divider from "./Divider"
import { Link } from "react-router-dom"


const ReviewSection = () => {
    return (
        <>
        	<div className="review-sec">
				<Container>
					<Row className="d-flex py-5 py-xl-3 ">
						<Col xxl={8} className="text-center py-4 bg-review-circle order-1 order-xl-0">
						<Row className="justify-content-xl-around justify-content-between pb-xl-5 px-3 px-xl-0 pb-5" >
							<ReviewIndividual bgCicle={'purple-circle'} rvIn={"cj"} name={"Cheryl L Jones"} initial={"C"} review={"We are very happy with the services provided by QA Digital Advertising. Our business is new and there are…"} />
							<ReviewIndividual bgCicle={'green-circle'} rvIn={"dl"} name={"Diana Lambert"} initial={"D"} review={"Couldn’t be happier. This company was receptive to all my requests and produced an awesome looking website…"} />
						</Row>
						<Row className="justify-content-xl-around justify-content-between pt-xl-5 px-3 px-xl-0 pt-5" >
							<ReviewIndividual bgCicle={'blue-circle'} rvIn={"tz"} name={"Torogoz"} initial={"T"} review={"Doy gracias a QA Digital Advertising por su excelente trabajo en la creación de mi página web para mi negoció…"} />
							<ReviewIndividual bgCicle={'red-circle'} rvIn={"aq"} name={"AQ Globall Inc"} initial={"A"} review={"Muchas gracias por ayudarnos en la estrategia de posicionamiento de nuestro sitio web y por la excelente administración de nuestras…"} />
						</Row>
						</Col>
						<Col xxl={4} className="align-self-center order-0 order-xl-1 mt-0 mt-xl-3 mb-5 mb-xl-3 text-center text-xl-start">
							<h3 className="lato h-45 text-white bold h-sm-30 text-center text-xl-start">Google<br />
								<span className="h-55 text-white w-900 h-sm-30 text-center text-xl-start">REVIEWS</span>
							</h3>
							<Divider width="50%" color='#ffffff' height="4px" clas="mx-xs-auto"/>
							<p className="text-white montserrat py-4 t-14 text-center text-xl-start">Our objective is to grow alongside our clients and help boost their businesses</p>
							<Link to={"/#contact"} className="port-button text-white montserrat px-4 bg-grad-blue h-20 t-sm-14">SEE MORE REVIEWS</Link>
						</Col>
					</Row>
				</Container>
			</div> 
        </>
    )
}

export default ReviewSection;