import { Col } from "react-bootstrap";

const ProjectsCard = ({ image, title, to}) => {
    return (
        <>
            <Col xl={3} xs={6} className="align-self-center text-center">
                <img src={image} alt="" width={"100%"} loading="lazy"/>
                <a href={to} target="_blank" className="montserrat project-t t-sm-11 t-14">{title}</a>
            </Col>
        </>
    )
}

export default ProjectsCard;