import React from "react";
import { Col } from "react-bootstrap";

const DiferencesCard = ({titleLine1, titleLine2, description, marg}) => {
    return (
        <>
            <Col xl={6} xs={12} className={`diferences pt-xl-5 pt-4 mt-xl-3 px-xl-5 px-4 pb-xl-4 ${marg}`}>
                <h3 className="montserrat se-title h-20 w-800 color-9d h-sm-20">{titleLine1} <br /> {titleLine2}</h3>
                <p className="pb-xl-3 montserrat t-14 lh-23 text-justify">{description}</p>
            </Col>
        </>
    )
}

export default DiferencesCard;