import { Row, Col, Container } from "react-bootstrap";
import Divider from "./Divider";
import { ContacUs, WebsiteMap, SeoIndustries } from "../data";
import AddressPhone from "./AddressPhone";
import IMAGES from "../Images";


const Footer = () => {
    return (
        <>
        <div className="bg-footer py-5 px-3 px-xl-0">
            <Container className="p-0">
                <Row className="m-0 p-0">
                    <Col xxl={2} className="ps-0">
                        <h6 className="foo-title lato">WEBSITE MAP</h6>
                        <Divider width="170px" color='#ffffff' height="2px" />
                        {WebsiteMap.map((map) => {
                            return(
                                <a href={map.link} className="foo-sitemap-a text-white montserrat py-2" key={map.name}>{map.name}</a>
                            )
                        })}
                    </Col>
                    <Col xxl={2} className="">
                        <h6 className="foo-title lato">SEO INDUSTRIES</h6>
                        <Divider width="170px" color='#ffffff' height="2px" />
                        {SeoIndustries.map((ind)=>{
                            return(
                                <a href={ind.link} className="foo-sitemap-a text-white montserrat py-2 pe-2" target="_blank" key={ind.name}>{ind.name}</a>
                            )
                        })}
                    </Col>
                    <Col xxl={2}>
                        <h6 className="foo-title lato">CONTACT US</h6>
                        <Divider width="170px" color='#ffffff' height="2px" />
                        {ContacUs.map((info)=> {
                            return(
                                <AddressPhone {...info} classes={"foo-address-tel"} whereTo="_self" key={info.name}>{info.name}</AddressPhone>
                            )
                        })}
                    </Col>
                    <Col xxl={3}>
                        <h6 className="foo-title lato">LOCATION</h6>
                        <Divider width="170px" color='#ffffff' height="2px" />                        
							<AddressPhone image={IMAGES.mapWhite} href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x89b7ddca8bcfe903:0x5982abeea75887d5?entry=s&sa=X&hl=en-us&ved=2ahUKEwjM9q25yOX5AhVDFlkFHSwEDFoQ4kB6BAgVEAI" classes="foo-address-tel" colClass="foo-less-m" whereTo="_blank" maxWidth={24}>706 Crain Highway North, Suite A Glen Burnie, MD 21061</AddressPhone>
							<AddressPhone image={IMAGES.mapWhite} href="https://goo.gl/maps/ojnpLdxN4A3UDm1v7" classes="foo-address-tel" colClass="foo-less-m" whereTo="_blank" maxWidth={24}>817 Silver Spring Ave. Silver Spring, MD 20910</AddressPhone>
                    </Col>
                    <Col xxl={2}>
                        <h6 className="foo-title lato">FOLLOW US</h6>
                        <Divider width="170px" color='#ffffff' height="2px" />
                        <a href="https://www.instagram.com/qadigitaladvertising/" target="_blank" className="social-icon-f text-center align-items-center justify-content-center mt-3 me-2">
                        <i className="bi bi-instagram social-icon color-43"></i>
                        </a>
                        <a href="https://www.instagram.com/qadigitaladvertising/" target="_blank" className="social-icon-f text-center align-items-center justify-content-center mt-3 me-2">
                        <i className="bi bi-facebook social-icon color-43"></i>
                        </a>
                        <a href="https://www.instagram.com/qadigitaladvertising/" target="_blank" className="social-icon-f text-center align-items-center justify-content-center mt-3 me-2">
                        <i className="bi bi-youtube social-icon color-43"></i>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="copyright py-4">
            <p className="text-center montserrat t-11 color-22 m-0">© Copyright 2024 | All rights reserved</p>
        </div>
        </>
    )

}

export default Footer;