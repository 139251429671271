import { Col } from "react-bootstrap";

const ProjectsPorfolioCard = ({ image, to}) => {
    return (
        <>
            <Col xxl={4} className="align-self-center text-center px-4 py-3 port-animated">
                <img src={image} alt="" width={"100%"} className="px-xl-5 px-4 py-xl-4 pt-3 pb-5" loading="lazy" />
                <a href={to} target="_blank" className="montserrat button-portfolio text-white bg-grad-purple w-600">SEE WEBSITE</a>
            </Col>
        </>
    )
}

export default ProjectsPorfolioCard;