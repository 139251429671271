import React from "react";
import FormBlack from "../Components/FormBlack";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Divider from "../Components/Divider";
import AddressPhone from "../Components/AddressPhone";
import IMAGES from "../Images";
import ServicesCard from "../Components/Services";
import { DesignServicesInfo, ProjectsInfo, MeasureDifference } from "../data";
import Formfooter from "../Components/FormFooter";
import TeamSection from "../Components/TeamSection";
import ProjectsCard from "../Components/Projects";
import ReviewSection from "../Components/ReviewsSection";
import DiferencesCard from "../Components/Diferences";

const WebDesign = () => {
	return (
		<>
			<div className="bg-banner-web-design-no1 pb-5 pb-xl-3">
				<Container>
					<Row className="pt-xl-3 pb-xl-5">
						<Col xl={6} className="ps-xl-5 px-4 pt-xl-2 pt-5 align-self-center">
							<h1 className="text-white montserrat h-60 w-800 m-0 pt-1 h-sm-35 text-start">N°1 WEB DESIGN
							<span className="h-40 block h-sm-25">COMPANY IN MARYLAND</span></h1>
							<Divider width="90%" color='#6f35d6' height="4px" clas="my-xl-4 my-2"/>
							<h3 className="text-white montserrat t-18 t-sm-14 lh-15">Our Maryland Web Design experts take charge of creating the visual strategy and content, keeping your brand in mind.</h3>
							<Row>
								<Col xl={12} className="py-4 ps-xl-0 text-xl-start gx-4">
									<a href="tel:+12405936567" className="call-button text-white montserrat px-xl-4 bg-color-20 me-xl-4 me-2 py-2 bold">240-593-6567</a>
									<a href="tel:+12405936567" className="call-button color-6f montserrat px-3 bg-white py-2 bold">Featured Projects</a>
								</Col>
								<h3 className="text-white montserrat bold t-14 pt-xl-4 pt-2">Our Offices</h3>
								<Col xl={5} className="">
									<AddressPhone image={IMAGES.mapAlt}  classes="a-address-tel bold" whereTo="_blank">Silver Spring, Maryland</AddressPhone>
									<AddressPhone image={IMAGES.mapAlt}  classes={'a-address-tel bold'} whereTo="_self">Wheaton, Maryland</AddressPhone>
								</Col>
								<Col xl={5} className="">
									<AddressPhone image={IMAGES.mapAlt}  classes="a-address-tel bold" whereTo="_blank">Glen Burnie, Maryland</AddressPhone>
									<AddressPhone image={IMAGES.mapAlt}  classes={"a-address-tel bold"} whereTo="_self">Falls Church, Virginia</AddressPhone>
								</Col>
							</Row>
						</Col>
						<Col xl={6}>
							<img src={IMAGES.webDesignCompany} width={"100%"} className="py-xl-4 py-3" ></img>
						</Col>
					</Row>
				</Container>
			</div>

			<FormBlack textTitle={'Launch Your Project!'}/>

			<div className="py-xl-5 py-4" >
				<Container className="web-design-why-us px-xl-5 pb-xl-5 pt-xl-4">
					<Row className="pe-xl-5">
						<Col xl={6} className="text-xl-end text-center pe-xl-5 order-xl-0 order-1">
							<img src={IMAGES.whyUsWebDesign} width={"90%"} className="py-xl-2 py-3 rounded-border" ></img>
						</Col>
						<Col xl={6} className="pe-xl-5 align-self-center px-4 ps-xl-3 py-5 py-xl-3 order-xl-1 order-0">
							<h2 className="lato h-tittle h-45">WHY US?</h2>
							<Divider width="50%" color='#20D9E4' height="7px" clas="mt-xl-2 mb-xl-4 my-3"/>
							<p className="color-33 montserrat t-14 pe-xl-5 mb-xl-5">Our gifted designers will transform your ideas and vision into a design that illustrates what you want to convey. The ideal visual strategy will lead your visitors to take action, helping you achieve your goals!</p>
							<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-color-88 me-4 py-2 bold mt-xl-5">240-593-6567</a>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="py-3 py-xl-5">
				<Container className="py-3 py-xl-5 px-4 px-xl-3">
					<Row className="pb-4 ps-3 ps-xl-3">
						<Col xl={4} className="px-0">
							<h3 className="montserrat h-35 h-sm-25 nm-260 w-600">What’s Included In Our Maryland Web
							<span className="lato h-tittle h-45 h-sm-30 block"> DESIGN SERVICES?</span>
							</h3>
						</Col>
						<Col xl={6} className="align-self-center ps-0 ps-xl-3">
							<Divider width="80%" color='#20D9E4' height="7px" clas="mt-xl-5"/>
						</Col>
					</Row>
					<Row className="pt-3 pt-xl-0 px-3 px-xl-0">
						<ServicesCard marg="me-xl-2 me-0 mb-4 mb-xl-3 width-31 " key={DesignServicesInfo.title} {...DesignServicesInfo[0]} imgHeight={55}/>
						<ServicesCard marg="ms-xl-2 me-xl-2 me-0 mb-4 mb-xl-3 width-31" key={DesignServicesInfo.title} {...DesignServicesInfo[1]} imgHeight={55}/>
						<ServicesCard marg="ms-xl-2 me-xl-2 me-0 mb-2 mb-xl-3 width-31" key={DesignServicesInfo.title} {...DesignServicesInfo[2]} imgHeight={55}/>
					</Row>
					<Row className="pt-3 pt-xl-0 px-3 px-xl-0">
						<ServicesCard marg="me-xl-2 me-0 mb-4 mb-xl-3 width-31" key={DesignServicesInfo.title} {...DesignServicesInfo[3]} imgHeight={55}/>
						<ServicesCard marg="ms-xl-2 me-xl-2 me-0 mb-4 mb-xl-3 width-31" key={DesignServicesInfo.title} {...DesignServicesInfo[4]} imgHeight={55}/>
						<ServicesCard marg="ms-xl-2 me-xl-2 me-0 mb-2 mb-xl-3 width-31" key={DesignServicesInfo.title} {...DesignServicesInfo[5]} imgHeight={55}/>
					</Row>
					<Row className="justify-content-center pt-xl-5 pt-4 text-center">
						<Col xl={4} xs={10}className="px-xl-4 px-0">
							<a href="#contact" className="port-button text-white montserrat px-4 bg-color-88 t-14 mx-xs-auto bold">START YOUR PROJECT WITH US!</a>
						</Col>
					</Row>

				</Container>
			</div> 

			<TeamSection />

			<div className="recent-projects rounded-0 bg-white pb-xl-5">
				<Container className="py-5">
					<Row className="pb-4 px-3 px-xl-0">
						<Col xxl={4} className="px-3 px-xl-0">
							<h3 className="lato">Featured <br />
								<span className="h-tittle h-45 h-sm-30">PROJECTS</span>
							</h3>
							<Divider width="280px" color='#20D9E4' height="4px" clas="" />
						</Col>
					</Row>
					<Row className="py-2 py-xl-4">
						<ProjectsCard {...ProjectsInfo[0]} key={ProjectsInfo[0].title}/>
						<ProjectsCard {...ProjectsInfo[6]} key={ProjectsInfo[6].title}/>
						<ProjectsCard {...ProjectsInfo[7]} key={ProjectsInfo[7].title}/>
						<ProjectsCard {...ProjectsInfo[3]} key={ProjectsInfo[3].title}/>
					</Row>
					<Row className="justify-content-center pt-xl-5 pt-4 text-center">
						<Col xl={3} xs={10}className="px-4">
							<a to={"/portfolio"} className="port-button text-white montserrat px-4 bg-color-88 t-14 mx-xs-auto bold">SEE ALL PROJECTS</a>
						</Col>
					</Row>
				</Container>
			</div>

			<ReviewSection />

			<div className="py-xl-5">
				<Container className="py-xl-5">
					<Row>
						<Col xl={6} className="pe-xl-5 order-xl-0 order-1 px-4">
							<p className="color-54 montserrat t-14 w-600 mb-0 mb-xl-2">Learn more about us & our services</p>
							<h2 className="h-tittle h-45 lato pe-xl-5 h-sm-25">Why we are the #1 Digital Marketing Agency in Maryland</h2>
							<Divider width="20%" color='#20D9E4' height="6px" clas="my-xl-4" />
							<h3 className="lato color-36 th-25 w-600 mb-xl-5 mb-0 h-sm-20 py-3 py-xl-0">| We Drive Measurable Results |</h3>
							<p className="montserrat t-16 color-36 bold mt-xl-4 nm-right-100">AT QA DIGITAL ADS, WE ELEVATE YOUR BUSINESS GROWTH WITH EXPERT DIGITAL MARKETING STRATEGIES.</p>
							<p className="t-14 montserrat color-36 nm-right-100">Our commitment to your business growth is unwavering, reflected in every strategy and campaign we develop. Here’s how we make a difference:</p>
						</Col>

						<Col xl={6} className="order-xl-1 order-0 px-4 px-xl-3 pb-4 pb-xl-3">
						<img src={IMAGES.qaWhyUsWebDesign} width={"96%"} className="nm-top-100" ></img>
						</Col>
					</Row>
					<Row className="pt-xl-4 px-4 px-xl-0">
						{MeasureDifference.map(data => {
							return(
								<DiferencesCard {...data} marg="mx-0 mx-xl-2 mb-4 mb-xl-3 width-48" key={MeasureDifference.title} />
							)
						})}
					</Row>
				</Container>
			</div>

			<div className="py-xl-5 py-4">
				<Container className="unlock-potential ps-xl-5 pt-xl-3 pt-xl-5 pt-4 px-4">
					<Row className="ps-xl-3 text-center text-xl-start">
						<Col xxl={6} className="px-5 align-self-center">
						<h2 className="h-55 text-white lato w-800 h-sm-30 text-xl-start text-center">Unlock Your 
						<span className="block"> Business Potential!</span></h2>
						<Divider width="70%" color='#20D9E4' height="6px" clas="my-4 mx-xs-auto" />
						<p className="text-white text-xl-start text-center t-16 lato w-500 pe-xl-5 me-xl-5">Boost Your Revenue and Expand Your Reach with our proven SEO & web design strategies.</p>
						<a to={"tel:+12407080023"} className="port-button text-white montserrat px-4 bg-color-88 t-14 mx-xs-auto bold mx-xs-auto">240-708-0023</a>
						</Col>
						<Col xxl={6} className="nm-top-100 text-center">
						<img src={IMAGES.businessSection} width={'50%'} className="fw-sm"/>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="belive-section pb-xl-5 pb-5">
				<Container className="py-xl-5 pb-5">
					<Row>
						<Col xl={5} className="p-0 text-center order-1 order-xl-0">
						<img src={IMAGES.believeWomen} width={'90%'} className="nm-top-100"/>
						</Col>
						<Col xl={5} className="p-xl-0 px-4 text-center text-xl-start order-0 order-xl-1 pt-5">
						<h2 className="h-55 text-white lato w-600 h-sm-30">Don't <br />Believe Us?
						<span className="block w-900"> Try Us!</span></h2>
						<Divider width="30%" color='#20D9E4' height="6px" clas="my-xl-4 mx-xs-auto my-3" />
						<p className="lato th-25 w-600 text-white">We Drive Measurable Results</p>
						<p className="text-white t-14 mb-0">Search Google for the following keywords and see how good we are and how much money we have helped our clients save and earn through organic search results.</p>
						</Col>
					</Row>
					<Row className="py-xl-5 pt-4 pb-5">
						<Col xl={7} className="mx-auto pb-xl-5">
						<p className="text-white lato h-40 w-900 text-center ln-45 pb-xl-4 h-sm-30">See Our Proven <br />
						SEO Results</p>
						<p className="text-center text-white h-23 lato pb-xl-5 pb-5">Our specialized strategies have helped numerous clients achieve significant growth; we have real success stories to prove it.</p>
						</Col>
					</Row>
				</Container>

			</div>

			<div className="success-sec">
				<Container>
				<Row className="justify-content-around px-4 px-xl-5">
						<Col xxl={3} className="ps-4 pe-2 py-xl-5 py-3 mb-4 mb-xl-0 sucsses-example">
							<Row className="justify-content-center ps-2 pe-4 pb-4">
								<Col xxl={8}>
									<img src={IMAGES.mistyLogoColor} width={'144px'} />
								</Col>
							</Row>
							<p className="t-18 color-56 montserrat bold mb-2">Top 1</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Keyword: </b>
								<a href="https://www.google.com/search?q=Cleaning+Services+In+Pasadena%2C+MD&rlz=1C1VDKB_esCO1047CO1047&oq=Cleaning+Services+In+Pasadena%2C+MD&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIICAEQABgWGB4yCAgCEAAYFhge0gEHMTU5ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8" target="_blank" className="color-36">Cleaning Services In Pasadena, MD</a>
							</p>
							<p className="color-36 t-14 montserrat">We help them save over $1,000 per month (Estimated cost if they were to invest on Google Ads for the same keyword)</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Competitors: </b>Private</p>
						</Col>
						<Col xxl={3} className="ps-4 pe-2 py-xl-5 py-3 mb-4 mb-xl-0 sucsses-example">
							<Row className="justify-content-center ps-2 pe-4 pb-4">
								<Col xxl={12}>
									<img src={IMAGES.bmaLogoColor} width={'249px'} />
								</Col>
							</Row>
							<p className="t-18 color-56 montserrat bold mb-2">Top 1</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Keyword: </b>
								<a href="https://www.google.com/search?q=Inground+liner+contractor+in+Pasadena%2C+Maryland&rlz=1C1VDKB_esCO1047CO1047&oq=Inground+liner+contractor+in+Pasadena%2C+Maryland&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBBzE1MWowajSoAgCwAgA&sourceid=chrome&ie=UTF-8" target="_blank" className="color-36">Inground liner contractor in Pasadena, Maryland</a>
							</p>
							<p className="color-36 t-14 montserrat">We help them save over $1,100 per month (Estimated cost if they were to invest in Google Ads for the same keyword)</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Competitors: </b>Private</p>
						</Col>
						<Col xxl={3} className="ps-4 pe-2 py-xl-5 py-3 mb-4 mb-xl-0 sucsses-example">
							<Row className="justify-content-center ps-2 pe-4 pb-4">
								<Col xxl={12}>
									<img src={IMAGES.habibLogo} width={'249px'} />
								</Col>
							</Row>
							<p className="t-18 color-56 montserrat bold mb-2">Top 3 & going up</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Keyword: </b>
								<a href="https://www.google.com/search?q=Personal+Trainer+in+Glen+Burnie%2C+MD&oq=Personal+Trainer+in+Glen+Burnie%2C+MD&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBBzQyNmowajeoAgCwAgA&sourceid=chrome&ie=UTF-8" target="_blank" className="color-36">Personal Trainer in Glen Burnie, MD</a>
							</p>
							<p className="color-36 t-14 montserrat">We help them save over $17,000 per month (Estimated cost if they were to invest in Google Ads for the same keyword)</p>
							<p className="color-36 montserrat t-14 mb-2"><b>Competitors: </b>Private</p>
						</Col>
					</Row>

				</Container>
			</div>

			<div className="py-xl-5 py-4">
				<Container className="py-xl-5">
					<Row>
						<Col xl={5} className="p-0 text-center align-self-center">
						<img src={IMAGES.pageComputer} width={'90%'} className=""/>
						</Col>
						<Col xl={7} className="pt-4 pt-xl-3 px-4 px-xl-3 text-center">
						<h2 className="h-45 h-tittle lato w-800 h-sm-25 text-center text-xl-start">QA Digital Ads:
						<span className="block"> 3 Cornerstones of Success</span></h2>
						<p className="color-56 montserrat t-14 bold text-start">#Maryland Web Design Company</p>
						<p className="t-14 montserrat color-36 pb-xl-2 text-start">At QA Digital Ads, we distill digital marketing into four core elements, ensuring a robust and streamlined strategy for your business:</p>
						<p className="t-14 montserrat color-36 pb-xl-1 bold text-start">GRASPING YOUR VISION</p>
						<p className="t-14 montserrat color-36 pb-xl-2 text-justify">Digital marketing thrives on customization. Recognizing this, we delve deep to grasp the essence of your brand, its core values, and aspirations. Our approach is to engage in a dialogue that uncovers your industry position, brand identity, niche, target audience, and competitive edge. We aim to pinpoint the business growth you seek, ensuring our strategy aligns perfectly with your vision.</p>
						<p className="t-14 montserrat color-36 pb-xl-1 bold text-start">STRATEGIZE AND OPTIMIZE</p>
						<p className="t-14 montserrat color-36 pb-xl-1 text-justify">Armed with a comprehensive understanding of your online footprint and aspirations, we craft a bespoke digital marketing plan spanning the next twelve months. Our strategy may integrate SEO to elevate your Google rankings, enhance visibility through targeted ads, or employ other proven techniques to drive high-quality traffic and boost your sales and profits—results you can quantify.</p>
						<p className="t-14 montserrat color-36 pb-xl-1 text-justify">Our first step is to fortify your website by addressing any technical issues. We then enhance your online presence and tailor your content to match the search queries of your ideal customers. By building authoritative links, we increase your site’s credibility, ensuring it’s indexed correctly by Google and easily discoverable by your audience.</p>
						<p className="t-14 montserrat color-36 pb-xl-1 text-justify">We analyze your competitors’ successful tactics to strategize how to surpass them. Lastly, we implement robust tracking systems to consistently monitor and report your progress, providing clear insights into your growth trajectory.</p>
						<p className="t-14 montserrat color-36 pb-xl-1 bold text-start">EXECUTE WITH PRECISION</p>
						<p className="t-14 montserrat color-36 pb-xl-1 text-justify mb-4 mb-xl-0">Following the tailored strategy blueprint, we diligently execute the plan daily. Within 6-8 months, we can project a reliable forecast of potential traffic, lead generation, and customer inquiries. Our focused efforts enhance your targeted visibility, prompt Google to elevate your site’s ranking, and amplify your brand’s presence, all of which funnel potential customers to your digital doorstep.</p>
						<a href="tel+12407080023" className="port-button text-white montserrat px-4 bg-color-88 t-16 bold">240-708-0023</a>
						</Col>
					</Row>
					<Row className="pt-xl-5 pt-5 px-4 px-xl-3">
						<Col xl={6} className="pe-xl-3 px-0">
						<h2 className="h-45 h-tittle lato w-800 pe-xl-5 h-sm-25 text-center text-xl-start">Personalized Digital Marketing Strategies For Your Business</h2>
						<Divider width="15%" color='#20D9E4' height="6px" clas="my-xl-4 fw-sm my-3" />
						<p className="t-14 montserrat color-36 pb-xl-1 bold">SEO Services</p>
						<p className="t-14 montserrat color-36 pb-xl-2 text-justify">Our comprehensive SEO assessment at QA Digital Ads pinpoints your website’s competitive edge and areas for improvement, providing a strategic advantage in the digital marketplace. We deliver actionable insights to fortify your online position against competitors. Our bespoke SEO strategies are crafted to enhance your digital footprint, driving sustained organic growth and long-term online success.</p>
						<p className="t-14 montserrat color-36 pb-xl-1 bold">Local SEO Services</p>
						<p className="t-14 montserrat color-36 pb-xl-1 text-justify">In today’s digital landscape, over 86% of consumers turn to the internet to find local services or products, making a first-page Google ranking essential. At QA Digital Ads, we specialize in tailoring our web marketing expertise to align your distinct brand and niche with the specific demands of your local community, ensuring you stay ahead of the competition.</p>
						<p className="t-14 montserrat color-36 pb-xl-1 bold">Integrated PPC as Part of Your SEO Strategy</p>
						<p className="t-14 montserrat color-36 pb-xl-1 text-justify mb-4 mb-xl-0">At QA Digital Ads, we specialize in propelling your business to the top of organic search results. Our core mission is to enhance your online presence through expert SEO services. However, we understand the value of a well-rounded digital marketing approach. That’s why, if you choose to allocate an additional budget for Google Ads, we offer seamless integration of PPC campaigns into your overall SEO plan. This dual strategy ensures that while we work on elevating your organic rankings, you also have the option to gain immediate visibility and traffic through targeted ads managed efficiently by our team.</p>
						<img src={IMAGES.googleImage} width={'100%'} className=""/>
						</Col>
						<Col xl={6} className="ps-xl-3 pt-4 pb-4 pb-xl-3 pt-xl-3 text-center text-xl-start">
						<img src={IMAGES.standingWomen} width={'100%'} className="not-small"/>
						<p className="t-14 montserrat color-36 pb-xl-1 bold text-start">Google Maps Optimization</p>
						<p className="t-14 montserrat color-36 pb-xl-1 text-justify">Elevate your local visibility with QA Digital Ads’ Google Maps Optimization service. As a critical component of Local SEO, appearing on Google Maps can significantly boost your business’s discoverability. When potential customers search for products or services in your area, our strategies ensure your business is one of the top visible locations, effectively “putting you on the map.”</p>
						<p className="t-14 montserrat color-36 pb-xl-1 bold text-start">SEO Evaluation</p>
						<p className="t-14 montserrat color-36 pb-xl-1 text-justify">An extensive SEO evaluation enhances your search engine rankings and user experience, increasing web traffic. Our SEO specialists at QA Digital Ads meticulously assess your site, identify and prioritize errors that impact traffic, and swiftly address these obstacles to attract new leads and customers.</p>
						<p className="t-14 montserrat color-36 pb-xl-1 bold text-start">Website Enhancement</p>
						<p className="t-14 montserrat color-36 pb-xl-1 text-justify">Set the stage for digital marketing triumph with a website optimized for users and search engines. QA Digital Ads’ comprehensive Website Enhancement involves improving site speed, repairing broken links, and ensuring content relevance and quality. Our team’s collaborative effort primes your site for peak performance and sales conversions.</p>
						<a href="tel+12407080023" className="port-button text-white montserrat px-4 bg-color-88 t-16 mx-xs-auto bold">240-708-0023</a>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="working-qa pt-xl-5 pt-5">
				<Container className="px-4 px-xl-3">
					<Row className="py-xl-5">
						<Col xl={8}>
						<h2 className="h-tittle h-45 lato w-800 h-sm-25 text-center text-xl-start">Working with the best Digital Marketing Agency in Maryland, Virginia, and Washington, D.C</h2>
						<Divider width="22%" color='#20D9E4' height="6px" clas="my-4 fw-sm" />
						</Col>
					</Row>
					<Row>
						<Col xl={6} className="pe-xl-4 text-center text-start text-xl-start">
							<p className="t-14 montserrat color-36 pb-xl-1 bold mb-xl-0 text-start">Flexible Engagement | NO CONTRACTS</p>
							<p className="t-14 montserrat color-36 pb-xl-2 text-justify">Our confidence in delivering results is reflected in our flexible contracts. We believe in earning your trust through visible, measurable outcomes, not binding agreements.</p>
							<p className="t-14 montserrat color-36 pb-xl-1 bold mb-xl-0 text-start">Expert Web Design Services</p>
							<p className="t-14 montserrat color-36 pb-xl-2 text-justify">At QA Digital Ads, we prioritize crafting a stunning and functional online presence for your business. Our web design services are the cornerstone of our digital marketing strategy, ensuring your brand stands out and captivates potential customers from the first click.</p>
							<a href="/portfolio" className="port-button text-white montserrat px-4 bg-color-88 t-16 mx-xs-auto bold my-xl-5">Visit our portfolio</a>
							<p className="t-14 montserrat color-36 pb-xl-1 bold mt-4 mb-xl-0 text-start">Dominate Search Rankings</p>
							<p className="t-14 montserrat color-36 pb-xl-2 text-justify">We aim to place you on Google’s first page and push your brand into the top tier. Our SEO expertise is more than visibility; it’s about dominating the digital landscape to drive traffic and increase engagement.</p>
							<p className="t-14 montserrat color-36 pb-xl-1 bold mb-xl-0 text-start">Boost Your Brand Exposure</p>
							<p className="t-14 montserrat color-36 pb-xl-2 text-justify">Amplify your brand’s reach with our strategic marketing initiatives. We focus on elevating your brand’s profile, ensuring your unique message resonates with your audience and solidifies your market presence.</p>
							<p className="t-14 montserrat color-36 pb-xl-1 bold mb-xl-0 text-start">Accelerate Business Growth</p>
							<p className="t-14 montserrat color-36 pb-xl-2 text-justify">Our digital marketing strategies are designed to convert traffic into sales, expand your customer base, and scale your business. Watch as your investment translates into a consistent increase in inquiries and revenue.</p>
							<img src={IMAGES.manWithLaptop} width={'75%'} className="ms-xl-4"/>
						</Col>
						<Col xl={6} className="ps-xl-4 pb-5 pb-xl-3 text-center text-xl-start">
							<img src={IMAGES.analiticsImage} width={'85%'} className="nm-top-100 ms-xl-5 not-small"/>
							<p className="t-14 montserrat color-36 pb-xl-1 bold mb-xl-0 mt-xl-5 mt-4 text-start">Partnership and Accessibility</p>
							<p className="t-14 montserrat color-36 pb-xl-2 text-justify mb-xl-4">We’re more than a service provider; we’re your digital marketing partner. Our team is always on hand to address your needs, answer questions, and keep you informed. Experience personalized service with a team that values your success.</p>
							<p className="t-14 montserrat color-36 pb-xl-1 bold mb-xl-0 text-start">Transparent Monthly Reporting</p>
							<p className="t-14 montserrat color-36 pb-xl-2 text-justify mb-xl-4">Stay informed with our comprehensive monthly reports. We keep you in the loop on your campaign’s progress, industry shifts, and algorithm updates, ensuring you’re always a step ahead.</p>
							<p className="t-14 montserrat color-36 pb-xl-1 bold mb-xl-0 text-start">In-House Excellence</p>
							<p className="t-14 montserrat color-36 pb-xl-2 text-justify mb-xl-4">Our in-house experts meticulously handle every aspect of your campaign. Trust in our commitment to provide safe, reliable, and effective SEO that aligns with your business goals.</p>
							<p className="t-14 montserrat color-36 pb-xl-1 bold mb-xl-0 text-start">Customer Acquisition</p>
							<p className="t-14 montserrat color-36 pb-xl-2 text-justify mb-4">We harness our search marketing expertise to draw in potential customers, convert them into loyal clients, and drive the growth of your business through strategic customer acquisition.</p>
							<a href="tel:+12407080023" className="port-button text-white montserrat px-4 bg-color-88 t-16 mx-xs-auto bold my-xl-5">240-708-0023</a>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="py-5">
				<Container className="pt-xl-4 px-4 px-xl-3">
					<h2 className="FAQ-Tittle h-65 montserrat w-900 text-center"><span>FAQs</span></h2>
					<Accordion>
					<Accordion.Item eventKey="0" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHAT IS PROFESSIONAL WEB DESIGN?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
							Professional web design encompasses a comprehensive website design approach, including aesthetics, user experience, SEO, and technical details. It involves a strategic layout, high-quality graphics, and content that aligns with the brand’s identity and goals.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="1" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">HOW MUCH SHOULD A WEB DESIGNER COST?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						The cost of a web designer can vary widely based on experience, project complexity, and geographic location. For a basic website, prices might start around $1,500, while more complex sites can exceed $5,000.<br />
						<b>Note:</b> It’s important to balance affordability with quality and ensure the designer’s style aligns with your vision.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHAT ARE THE THREE TYPES OF WEB DESIGN?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						The three main types of web design are static, dynamic or CMS, and eCommerce. Static websites are basic with fixed content, dynamic websites are interactive and content-rich, managed through a content management system (CMS), and eCommerce sites are designed for online sales and transactions.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="3" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">SHOULD I PAY FOR A WEB DESIGNER?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						Investing in a professional web designer can significantly benefit your online presence. A well-designed website can improve user experience, increase conversions, and reflect the professionalism of your brand. If these factors are crucial for your business, hiring a web designer is a wise decision.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="4" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">HOW MUCH DOES A PROFESSIONAL WEB DESIGNER COST?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						Professional web designers typically charge between $100 to $200 per hour. Project-based pricing can range from a few thousand dollars for a basic professional website to tens of thousands for a large, custom-designed site with various functionalities.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="5" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">HOW DO I FIND SOMEONE TO DESIGN MY WEBSITE?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						To find a web designer, you can search online portfolios, ask for referrals, or use professional networks like LinkedIn. Look for a designer whose style resonates with your brand and has positive testimonials or a strong track record.

						<a href="/portfolio" className="block bold" style={{color: "#c36", textDecoration: "none"}}>[Check out our portfolio]</a>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="6" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHAT IS THE DIFFERENCE BETWEEN A WEBSITE DEVELOPER AND A WEBSITE DESIGNER?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						A website designer focuses on the visual aspects and user experience, while a developer handles the coding and technical side, turning the design into a functioning site. Both roles are essential for a successful website.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="7" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">HOW DO YOU CHOOSE A WEB DESIGN COMPANY IN 2023?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						When choosing a web design company in 2023, consider their portfolio, client testimonials, and their approach to trends and technologies. Ensure they have a clear process, offer post-launch support, and understand your business goals. It’s also wise to discuss their approach to mobile responsiveness, SEO, and user experience.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="8" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHEN WILL YOUR WEBSITE LAUNCH?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						The launch timeline for your website depends on the complexity of the project. We strive to complete most of our projects within 4–6 months. However, it is important to note that each website development project is unique, and the specific requirements and features involved may affect the overall timeline. Our primary goal is to ensure a successful and seamless launch, so we dedicate the necessary time and resources to deliver a high-quality website that meets your expectations. Rest assured, we will work closely throughout the process to inform you of the progress and provide an estimated timeframe for your website launch.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="9" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WILL AI BE USED TO GENERATE YOUR WEBSITE?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						Our approach combines human expertise and limited use of AI technology. While our developers primarily utilize their coding skills within the platform’s framework, we occasionally employ AI for initial research purposes. However, it is crucial to highlight that implementing any code goes through a comprehensive review and quality assurance process conducted by our managers to ensure a human touch and a high-quality standard.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="10" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">HOW DOES QA DIGITAL TECHNOLOGIES APPROACH WEBSITE DESIGN AND DEVELOPMENT TO ENSURE CLIENT SATISFACTION?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						At QA Digital Technologies, we understand the critical role that website design and development plays in establishing a strong online presence. Our approach is centered around delivering superior outcomes to our clients and ensuring their satisfaction in the marketplace.
						<br />
						To begin with, we have a team of professional Digital Strategists who work closely with you to develop a comprehensive scope of work. This process involves incorporating industry best practices while addressing your specific business needs. By considering both these factors, we ensure that your website is designed and developed to maximize its potential for success.
						<br />
						Once the scope of work is defined, our dedicated project managers, designers, developers, and QA team collaborate to execute the plan efficiently. Throughout the entire process, we believe in involving our clients and keeping them informed about the progress of their projects. This transparent approach ensures that our clients have visibility into the development process and can provide feedback and suggestions at every stage.
						<br />
						At QA Digital Technologies, we pride ourselves on the satisfaction of our clients. We have a track record of delivering exceptional services and have received rave reviews from hundreds of clients on platforms such as Clutch, Google, and Facebook. These testimonials reflect our clients’ confidence and trust in our abilities.
						<br />
						We offer a free competitor strategy review as part of our initial account assessment to further demonstrate our commitment to client satisfaction. This review helps us understand your market landscape better and develop a strategy that sets you apart from your competitors. Additionally, we provide a detailed timeline and forecast so you can clearly understand the project’s progress and expected outcomes.
						<br />
						Our ability to start working on your project immediately sets us apart. We understand the importance of timely delivery and take pride in our efficiency. So, our expert team is ready to assist you if you have an urgent requirement or want to revamp your existing website.
						<br />
						Contact us today at 240-593-6567, and our team of specialists will be more than happy to review your account, provide a customized strategy, and discuss your timeline for success. Let QA Digital Technologies help you achieve your online goals with our website design and development expertise.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="11" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHICH WEBSITE CONTENT MANAGEMENT SYSTEMS DOES QA DIGITAL TECHNOLOGIES RECOMMEND FOR BUSINESSES?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						At QA Digital Technologies, we understand the importance of selecting the right website platform for your business. Our experienced professionals actively evaluate and review various website content management systems to determine their effectiveness. We offer expert guidance in choosing the most suitable platform that aligns with your industry’s best practices and unique business requirements.
						<br />
						To begin, our dedicated Digital Strategists will collaborate with you to create a comprehensive scope of work. This process involves careful consideration of your industry’s standards, ensuring that the recommended website content management system reflects the latest trends and innovations. We take a customized approach, considering your business needs to ensure a tailored solution.
						<br />
						Regardless of your industry or business type, we have extensive expertise assisting companies like yours. Our team will thoroughly assess your account to develop a strategy that includes a timeline and forecast of the project. As part of our commitment to your success, we even provide a complimentary competitor strategy review alongside our recommendations.
						<br />
						Whether you are in the e-commerce sector, professional services, or any other industry, our experts are here to guide you in making the right choice regarding website content management systems. Contact us today, and let our knowledgeable team assist you in achieving your online goals.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="12" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHY SHOULD I CHOOSE A DIGITAL MARKETING AGENCY OVER MANAGING IT MYSELF?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						While some businesses attempt to handle digital marketing in-house, the ever-evolving nature of Google’s algorithms often makes it more efficient to work with experts. A digital marketing agency brings specialized knowledge and keeps abreast of the latest changes, ensuring your marketing efforts are effective and your business stays ahead of the curve.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="13" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHAT IS DIGITAL MARKETING?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						Digital marketing encompasses all marketing efforts conducted online, such as websites, SEO, social media, and email campaigns. It contrasts with traditional advertising, which includes print and broadcast ads. With the digital landscape constantly expanding, online marketing is becoming increasingly vital for businesses.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="14" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHAT EXACTLY DOES A DIGITAL MARKETING AGENCY DO?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						A digital marketing agency employs a variety of online tools and strategies to enhance brand visibility, drive traffic, and generate leads. This could include immediate solutions like Google or Facebook Ads, along with long-term strategies such as SEO and website optimization. The goal is to test, monitor, and refine these methods to achieve optimal results.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="15" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">HOW DO YOU TRACK MY MARKETING PROGRESS AND ROI?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						We utilize advanced tools like Google Analytics to monitor your website’s performance. Our clients receive monthly reports and have access to an online dashboard for real-time updates on website activity, including visitor demographics, behavior, and conversion rates. This data helps us steer your marketing strategy toward success.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="16" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHAT IS THE COST OF HIRING A DIGITAL MARKETING FIRM?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						The investment in a digital marketing firm varies based on the complexity of your campaign and the competitiveness of your industry. Typically, fees range from $800 to $3,000+ per month. We offer a flat monthly rate that reflects the hours needed to achieve your goals, ensuring you get the best return on your investment.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="17" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHAT MAKES YOUR DIGITAL MARKETING AGENCY STAND OUT?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						Our dedication to personalized service sets us apart. We understand that your brand is unique, and we’re passionate about highlighting your value proposition to your audience. Our approach is tailored to amplify your brand and help you achieve your business aspirations.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="18" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">CAN DIGITAL MARKETING STRATEGIES BE INTEGRATED WITH TRADITIONAL ADVERTISING?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						Yes, integrating digital and traditional marketing can broaden your reach. Each method appeals to different audiences, and a combined approach can direct more potential customers to your digital platforms, enhancing engagement and conversion opportunities.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="19" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">CAN YOU CREATE A NEW LOGO FOR OUR BRAND?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						Certainly! Our skilled designers specialize in creating unique and professional logos for brands. We understand the importance of representing your brand identity through an eye-catching and memorable logo. Whether you’re starting a new business or looking to refresh your current brand, we can create a custom logo that perfectly captures the essence of your company. Learn more about our logo design services, and let us help you bring your brand to life with a stunning logo.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="20" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHAT CAN HIGH-END WEB DESIGN DO FOR YOU?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						High-end web design has the potential to transform your brand by creating a visually captivating and user-friendly website. It enables you to engage your target audience effectively, leaving a lasting impression that sets you apart from your competitors. Moreover, high-end web design can drive your sales beyond expectations, as it showcases your products or services and optimizes the user experience, making it easier for potential customers to purchase or engage with your business.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="21" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">WHAT IS THE IMPORTANCE OF WEB DESIGN IN CREATING A POSITIVE USER EXPERIENCE AND INCREASING CONVERSION RATES?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						Web design plays a crucial role in shaping users’ perceptions of your brand and holds the power to create a lasting impression. A well-designed website offers an intuitive and visually appealing experience across various devices and effectively communicates your brand’s message. By integrating responsive design, which seamlessly adapts to any screen size or platform, a website can cater to the increasing number of mobile users, who now account for more than half of all website traffic.
						<br />
						The importance of web design in generating conversions must be considered. A well-designed website has the potential to guide users through their journey, ensuring a smooth and engaging experience that ultimately leads to higher conversion rates. Additionally, integrating effective marketing strategies into the website build is essential for targeting the right audience and maximizing conversions. A website can decrease bounce rates and significantly increase conversion rates by incorporating advanced tracking, analytics, search engine optimization (SEO), search engine marketing (SEM), social media, and email marketing techniques.
						<br />
						By recognizing the significance of web design in creating a positive user experience and increasing conversion rates, businesses can establish a strong online presence, enhance brand authority, and ultimately achieve their desired goals. It is crucial to seize the opportunity to captivate users with an exceptional web design that showcases your brand and engages users, instilling trust and confidence in your products or services.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="22" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">HOW MANY WEBSITES HAS QA DIGITAL BUILT?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						QA Digital has an impressive record of building over 600 websites as part of their extensive work.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="23" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">HOW DOES QA DIGITAL IMPLEMENT RESPONSIVE WEB DESIGNS TO CATER TO MOBILE USERS?</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						At QA Digital, we understand the significance of mobile users in today’s digital landscape, as they constitute over 50% of all website traffic. We prioritize implementing responsive web designs to cater to this growing audience and enhance conversions while upholding your brand authority. This entails creating dynamic and adaptable website layouts that seamlessly adjust to any screen size or platform, ensuring an optimal user experience regardless of the device used. By leveraging this approach, we ensure that your website provides a consistent and user-friendly interface across various devices, enabling your business to connect with mobile users and maximize engagement effectively.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="24" className="my-4 border-0">
						<Accordion.Header className="FAQ-ITEM montserrat">CONTACT OUR DIGITAL MARKETING AGENCY TODAY!</Accordion.Header>
						<Accordion.Body className="t-14 montserrat color-54">
						For more personalized answers or to discuss how we can help your business thrive online, reach out to us. We’re here to guide you through the digital landscape and ensure your marketing efforts yield tangible results.
						</Accordion.Body>
					</Accordion.Item>
					</Accordion>
				</Container>
			</div>

			<div className="py-xl-4">
				<Container className="cta-ready-skyrocket py-5 text-center">
					<Row>
						<Col xl={6} className="mx-auto">
						<h2 className="h-45 lato text-white w-600 h-sm-30">Are You Ready to <span className="w-900">Skyrocket
						Your Business?</span></h2>
						<Divider width="30%" color='#20D9E4' height="6px" clas="my-4 mx-auto" />
						<p className="text-white t-15 lato w-600 mb-xl-4">Boost Your Revenue and Expand Your Reach with our proven<br />
						SEO & web design strategies.</p>
						<a href="tel:+12407080023" className="port-button text-white montserrat px-4 bg-color-88 t-16 mx-xs-auto bold my-xl-5">240-708-0023</a>
						</Col>
					</Row>
				</Container>
			</div>

			<Formfooter
				bgColor={'bg-white'}
				areaColor={"color-63"} 
				iconColor={"color-AB"} 
				serveColor={"color-63"} 
				colorCity={"color-33"} 
				erveryColor={"color-6f"} 
				bgForm={"bg-color-d9"}
				colorLabel={"color-6f"}
				colorInput={"color-6f white-border"}
				colorStroke={"t-stroke-color-6f"} 
				strokeText={"CONTACT US"}/>


		</>
	);
};

export default WebDesign;