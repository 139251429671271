import React, { useState } from "react";
import AddressPhone from "../Components/AddressPhone";
import IMAGES from "../Images";
import { Col, Container, Row } from "react-bootstrap";
import Divider from "../Components/Divider";
import ProjectsCard from "../Components/Projects";
import ProjectsPorfolioCard from "../Components/ProjectPorfolio";
import { ProjectsInfo, ProjectsPortfolio } from "../data";
import { Link } from "react-router-dom";


const Portfolio = () => {

	const [visibleItems, setVisibleItems] = useState(6);
	const itemsToShow = ProjectsPortfolio.slice(0, visibleItems);

	const handleLoadMore = () => {
		setVisibleItems(prevVisibleItems => Math.min(prevVisibleItems + 6, ProjectsPortfolio.length));
	  };

	return (
		<>
			<div className="port-bg-banner py-5">
				<Container>
					<Row>
						<Col xxl={6} className="px-4 px-xl-5 pb-5 align-self-bottom">
							<Row className="p-0">
								<Col xl={2} xs={3} className="align-self-center">
									<Divider width="100%" color='#6F35D6' height="5px"/>
								</Col>
								<Col xl={10} xs={9} className="px-0">
									<h3 className="montserrat port- h-20 t-sm-18 text-white m-0">
										Our Work
									</h3>
								</Col>
							</Row>
							<h1 className="lato port-linear-text h-65 w-800 h-sm-45">Professional<br /><span className="valorant h-75 bold port-linear-text h-sm-45"> Web Design</span></h1>
							<h1 className="text-white montserrat h-20 t-sm-18" style={{ paddingTop: 10 }}>We don’t use Templates. We design from scratch</h1>
							<Row>
								<Col xxl={9}>
									<h3 className="text-white kumbh-sans th-25 h-sm-20 pt-2 pt-xl-4">Visit Us</h3>
									<Divider width="70%" color='#09F2F2' height="3px" />
								</Col>
								<Col xxl={6} className="pt-2 pt-xl-4 ">
									<AddressPhone image={IMAGES.mapAlt} href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x89b7ddca8bcfe903:0x5982abeea75887d5?entry=s&sa=X&hl=en-us&ved=2ahUKEwjM9q25yOX5AhVDFlkFHSwEDFoQ4kB6BAgVEAI" classes="a-address-tel" whereTo="_blank">706 Crain Highway North, Suite A Glen Burnie, MD 21061</AddressPhone>
									<AddressPhone image={IMAGES.mapAlt} href="https://goo.gl/maps/ojnpLdxN4A3UDm1v7" classes="a-address-tel" whereTo="_blank">817 Silver Spring Ave. Silver Spring, MD 20910</AddressPhone>
								</Col>
								<Col xxl={6} className="pt-xl-4 pt-0">
									<AddressPhone image={IMAGES.phoneAlt} href='tel:+1240-593-6567' classes={'a-address-tel'} whereTo="_self">240-593-6567 (Eng.)</AddressPhone>
									<AddressPhone image={IMAGES.phoneAlt} href='tel:+1240-521-8700' classes={"a-address-tel"} whereTo="_self">240-521-8700 (Spa.)</AddressPhone>
								</Col>
							</Row>
						</Col>
						<Col xxl={6}>
							<img src={IMAGES.imageBannerPortfolio} width={"100%"} className="py-xl-4"></img>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="boost-brand">
				<Container>
					<Row className="d-flex">
						<Col xxl={6} className="order-1 order-xl-0">
							<img src={IMAGES.boostImage1} width={'100%'} className="boostImage1 pt-xl-5 pt-5"></img>
							<img src={IMAGES.boostImage2} width={'100%'} className="boostImage2"></img>
						</Col>
						<Col xxl={6} className="align-self-center order-0 order-xl-1 pt-5 pt-xl-3">
							<h3 className="montserrat bold h-35 h-sm-20">We help you <br />
								<span className="h-tittle h-45 h-sm-30">Boost Your Brand</span>
							</h3>
							<Divider width="205px" color='#20D9E4' height="4px" />
							<p className="py-3 mb-0 mb-xl-3 py-xl-3 montserrat t-14">
							A marketing agency can help your business reach new heights by creating a comprehensive marketing strategy that 
							targets your customers and helps you stand out from the competition.<br />We have over 14 years of experience helping
							 various-sized companies reach their unique branding goals.
							</p>
							<Row className="pt-1">
								<Col xxl={6} className="px-3 px-xl-4">
									<a href="tel:+12405936567" className="call-button text-white montserrat px-4 bg-grad-purple">240-593-6567</a>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="recent-projects">
				<Container className="py-5">
					<Row className="pb-4 px-3 px-xl-0">
						<Col xxl={4} className="px-3 px-xl-0">
							<h3 className="lato">Featured <br />
								<span className="h-tittle h-45 h-sm-30">PROJECTS</span>
							</h3>
							<Divider width="280px" color='#20D9E4' height="4px" clas="" />
						</Col>
					</Row>
					<Row className="py-2 py-xl-4">
						<ProjectsCard {...ProjectsInfo[0]} key={ProjectsInfo[0].title}/>
						<ProjectsCard {...ProjectsInfo[6]} key={ProjectsInfo[6].title}/>
						<ProjectsCard {...ProjectsInfo[7]} key={ProjectsInfo[7].title}/>
						<ProjectsCard {...ProjectsInfo[3]} key={ProjectsInfo[3].title}/>
					</Row>
					<Row className="justify-content-center pt-5 text-center">
						<Col xl={3} xs={10}className="px-4">
							<Link to={"/portfolio"} className="port-button text-white montserrat px-4 bg-grad-purple h-20 t-sm-14 mx-xs-auto">SEE FULL PORTFOLIO</Link>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="recent-projects-portafolio">
				<Container className="py-5">
					<Row className="pb-4">
						<Col xxl={4} className="px-0">
							<h3 className="montserrat h-35 w-600 h-sm-20 text-center text-xl-start">Recent <br />
								<span className="h-tittle lato h-45 h-sm-30">PROJECTS</span>
							</h3>
							<Divider width="180px" color='#7A5198' height="4px" clas="mx-xs-auto" />
						</Col>
					</Row>
					<Row>
						{itemsToShow.map((item) => {
					
							return(
								<ProjectsPorfolioCard key={item.to} {...item}/>
							)
						})}
					</Row>
					<Row className="justify-content-center text-center pt-5">
						<Col xxl={3} className="px-4">
						{visibleItems < ProjectsPortfolio.length && (
							<a onClick={handleLoadMore} className="port-button text-white montserrat px-4 bg-grad-purple" style={{cursor:"pointer"}}>SEE MORE PROJECTS</a>
      					)}
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Portfolio;